import styled from 'styled-components';
import * as styles from 'util/style/Styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${styles.MyzzymTheme.LightGrayBG};
  padding: 10px;
`;

export const Content = styled.ul`
  flex-shrink: 0;
  flex: 1;
  height: auto; /* 36px */
  ${styles.FlexEnd};
  gap: 30px;
`;
