import React from 'react';
import * as s from './ContactContentStyled';
import Content from 'components/molecules/content/Content';

const ContactContentPresenter = ({ item }) => {
  return (
    <Content>
      <s.TitleContainer>
        <s.Title>
          <s.HighLight>{item.id}. </s.HighLight>
          {item.name}
        </s.Title>
      </s.TitleContainer>
      <s.BranchItem>
        <s.BranchItemTitle>문의자</s.BranchItemTitle>
        <s.BranchItemDesc>{item.name}</s.BranchItemDesc>
      </s.BranchItem>

      <s.BranchItem>
        <s.BranchItemTitle>회사명</s.BranchItemTitle>
        <s.BranchItemDesc>{item.companyName}</s.BranchItemDesc>
      </s.BranchItem>
      <s.BranchItem>
        <s.BranchItemTitle>이메일</s.BranchItemTitle>
        <s.BranchItemDesc>{item.email}</s.BranchItemDesc>
      </s.BranchItem>
      <s.BranchItem>
        <s.BranchItemTitle>전화번호</s.BranchItemTitle>
        <s.BranchItemDesc>{item.phoneNumber}</s.BranchItemDesc>
      </s.BranchItem>
      <s.BranchItem>
        <s.BranchItemTitle>문의내용</s.BranchItemTitle>
        <s.BranchItemDesc>{item.contactContent}</s.BranchItemDesc>
      </s.BranchItem>
    </Content>
  );
};

export default ContactContentPresenter;
