/**
 * @author HEEYEON
 * @date 2021.01.07
 * @description show list
 */

import React, { useState } from 'react';
import ContactsDetailPresenter from './ContactsDetailPresenter';

const ContactsDetail = ({ items }) => {
  const [index, setIndex] = useState(0);
  const [currentItem, setCurrentItem] = useState(items[0]);

  const onChangeItem = (idx) => {
    setCurrentItem(items[idx]);
    setIndex(idx);
  };

  return (
    <ContactsDetailPresenter
      items={items}
      currentItem={currentItem}
      onChangeItem={onChangeItem}
      index={index}
    />
  );
};

export default ContactsDetail;
