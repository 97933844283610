import React from 'react';
import * as s from './ExamineContentStyled';
import Content from 'components/molecules/content/Content';
import Branch from './branch';
import Unit from './unit';

const ExamineContentPresenter = ({
  item,
  checkedList,
  handleCheckbox,
  handleExtra,
  reject,
  getLatLng,
}) => {
  return (
    <Content>
      {item ? (
        <>
          <s.TitleContainer>
            <s.Title>
              <s.HighLight>{item.id}. </s.HighLight>
              {item.branchName}
            </s.Title>
            <s.ButtonContainer>
              <s.Button
                deny
                onClick={() => {
                  if (checkedList.length === 0) {
                    alert('미흡한 항목을 선택해주시기 바랍니다');
                  } else {
                    reject(item);
                  }
                }}
              >
                반려
              </s.Button>

              <s.Button
                onClick={() => {
                  if (checkedList.length !== 0) {
                    alert('미흡한 항목 선택을 해제하고 승인해주시기 바랍니다.');
                  } else {
                    getLatLng(item.roadAddress);
                  }
                }}
              >
                승인
              </s.Button>
            </s.ButtonContainer>
          </s.TitleContainer>

          <s.GuideContainer>
            미흡한 항목을 선택하신 후 반려버튼을 클릭해주세요!
          </s.GuideContainer>

          <s.Container>
            <Branch item={item} handleCheckbox={handleCheckbox} />
            <Unit
              item={item}
              units={item.units}
              handleCheckbox={handleCheckbox}
            />
          </s.Container>

          <s.Comment>
            <s.BranchItemTitle>추가 의견</s.BranchItemTitle>
            <s.CommentInput
              onChange={handleExtra}
              placeholder={
                '추가 의견을 입력해주세요. ex) 주소 확인 결과 정확하지 않습니다.'
              }
            />
          </s.Comment>
        </>
      ) : (
        <s.Title></s.Title>
      )}
    </Content>
  );
};

export default ExamineContentPresenter;
