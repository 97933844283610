import React from 'react';
import * as s from '../BookingSideListStyled';

const Type = ({ filterList, filterItems, selectedFilter }) => {
  return (
    <s.TypeContainer>
      {filterList.map((filter, idx) => (
        <s.TypeItem
          key={idx}
          filter={filter}
          selectedFilter={selectedFilter}
          onClick={() => filterItems(filter)}
        >
          {filter}
        </s.TypeItem>
      ))}
    </s.TypeContainer>
  );
};

export default Type;
