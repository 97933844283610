import InnerContainer from 'components/molecules/inner/InnerContainer';
import BookingSideList from 'components/molecules/sidelist/BookingSideListContainer';
import React from 'react';
import BookingContent from './content';

const BookingDetailPresenter = ({
  bookingList,
  currentItem,
  filterList,
  setCurrentItem,
}) => {
  return (
    <InnerContainer>
      <BookingSideList
        category={'booking'}
        items={bookingList}
        filterList={filterList}
        setCurrentItem={setCurrentItem}
      />
      <BookingContent item={currentItem} />
    </InnerContainer>
  );
};

export default BookingDetailPresenter;
