// for error monitoring
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ENV from './config/config';

const { SENTRY } = ENV;

const initOptions = SENTRY.ENABLE_ERROR_HANDLER && { dsn: SENTRY.DSN };
const integrations = SENTRY.ENABLE_REQUEST_TRACING && {
  integrations: [new BrowserTracing()],
  tracesSampleRate: SENTRY.TRACING_SAMPLING_RATE,
};

const sentry = {
  initiate: () => {
    Sentry.init({ ...initOptions, ...integrations });
  },
};

export default sentry;
