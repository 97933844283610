/**
 *
 * @author ydoni
 * @date 2021.07.20
 * @description examine detail
 */

import React from 'react';
import InnerContainer from 'components/molecules/inner/InnerContainer';
import SideList from 'components/molecules/sidelist/SideListContainer';
import ExamineContent from './content';

const ExamineItemDetailPresenter = ({
  branchList,
  currentItem,
  onChangeItem,
  filterItem,
  index,
  typeIdx,
}) => {
  return (
    <InnerContainer>
      <SideList
        category={'examine'}
        items={branchList}
        index={index}
        typeIdx={typeIdx}
        onChangeItem={onChangeItem}
        filterItem={filterItem}
      />
      <ExamineContent item={currentItem} />
    </InnerContainer>
  );
};

export default ExamineItemDetailPresenter;
