import styled from 'styled-components';
import * as styles from 'util/style/Styles';

export const TitleContainer = styled.div`
  font-size: 1.125rem;
  padding: ${styles.Padding};
  ${styles.FlexBetween};
`;

export const Title = styled.div`
  flex: 10;
`;

export const HighLight = styled.strong`
  font-weight: 600;
`;

export const GuideContainer = styled.div`
  width: 100%;
  padding: ${styles.Padding};
  text-align: center;
  color: ${styles.MyzzymTheme.Red};
  background-color: ${styles.MyzzymTheme.LightGray};
`;

export const Container = styled.div`
  display: flex;
  height: ${window.innerHeight - 310}px;
`;

// 반려 및 승인 버튼
export const ButtonContainer = styled.ul`
  ${styles.Flex};
`;

export const Button = styled.li`
  cursor: pointer;
  color: ${(props) =>
    props.disable ? styles.MyzzymTheme.Gray : styles.MyzzymTheme.Red};
`;

//booking 정보
export const BookingContainer = styled.ul`
  width: 100%;
  overflow-y: scroll;
  border-right: ${(props) => (props.unit ? 0 : styles.Border)};
`;

export const BranchItem = styled.li`
  ${styles.FlexBetween};
  padding: ${styles.Padding};
`;

export const BranchItemTitle = styled.span`
  flex: 1;
  color: ${styles.MyzzymTheme.Gray};
`;

export const BranchItemLabel = styled.label`
  flex: 3;
  display: flex;
  ${styles.FlexBetween};
`;

export const BranchItemDesc = styled.span`
  flex: 6;
`;

export const BranchInput = styled(styles.Checkbox)`
  flex: 1;
`;

export const ImgGroup = styled.div`
  margin-bottom: 2%;
`;

export const ImgContainer = styled.ul`
  width: 100%;
  display: flex;
`;

export const ImgWrapper = styled.li`
  width: 20%;
  margin-right: 2%;
`;

export const Img = styled.img`
  height: 100%;
  width: 100%;
`;

export const ExpandImgWrapper = styled.div`
  width: 100%;
`;

// 추가의견
export const Comment = styled(BranchItem)`
  border-bottom: 0;
  border-top: ${styles.Border};
  width: 100%;
`;

export const CommentInput = styled.input`
  flex: 8;
`;

// 유닛
export const UnitContainer = styled.ul`
  border-bottom: ${styles.Border};
`;
