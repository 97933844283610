/**
 * @author suyeon-jung
 * @date 2021.07.21
 * @description 푸시알림 전송예약 버튼(for 이벤트 및 공지사항)
 */
import React, { useState } from 'react';
import CircleButton from 'components/atoms/CircleButton';
import { BsAlarm } from 'react-icons/bs';
import Form from 'views/myzzym/Notification/Form/reserve';

const NotificationReserveButton = ({ item }) => {
  const [showModal, setShowModal] = useState(false);
  const changeShowModal = () => setShowModal(!showModal);
  return (
    <>
      <CircleButton onClick={changeShowModal}>
        <BsAlarm size={20} style={{ margin: 0, padding: 0 }} />
      </CircleButton>
      {showModal && <Form changeShowModal={changeShowModal} item={item} />}
    </>
  );
};

export default NotificationReserveButton;
