import { api } from 'api/api-base';

export const branchApi = {
  /**
   * @GetMapping("/users/{userId}/branches")
   */
  searchBranchesByUser: (userId, params) =>
    api.get(`/users/${userId}/branches`, {
      params: params,
    }),

  /**
   * @PutMapping("/branches/{branchId}") - 브랜치 정보 업데이트
   */
  updateBranch: (branchId, branchInfo) =>
    api.put(`/branches/${branchId}`, branchInfo),

  /**
   * @PutMapping("/branches/{branchId}/examine/complete") - 검수완료(1)로 isExamine 업데이트 및 알림톡 전송
   */
  updateBranchExamineComplete: (branchId) =>
    api.put(`/branches/${branchId}/examine/complete`),

  /**
   * @PutMapping("/branches/{branchId}/examine/reject") - 검수반려(2)로 isExamine 업데이트 및 알림톡 전송
   */
  updateBranchExamineReject: (branchId, issue, extra) =>
    api.put(`/branches/${branchId}/examine/reject`, {
      issue: issue,
      extra: extra,
    }),
};
