import React from 'react';
import * as s from '../SideListStyled';
import * as format from 'util/fomatter/Formatter';

const ListPresenter = ({ category, items, onChangeItem, index }) => {
  /**
   * earning에서 사용됨
   */
  function getCountByStatus(earnings, status) {
    let ret = 0;
    earnings.map((earning) => {
      if (earning.payStatus === status) {
        ret++;
      }
    });
    return ret;
  }

  return (
    <s.ListContainer>
      {category === 'booking' &&
        items.map((item, idx) => (
          <s.ListItem
            key={idx}
            idx={idx}
            cur={index}
            onClick={() => onChangeItem(idx)}
          >
            <s.Top>
              <s.Item>bookingId {item.id}.</s.Item>
              <s.Item date>{format.dateToFormat(item.createdDate)}</s.Item>
            </s.Top>
            <s.Item earning examine={item.status === 2 ? true : 0}>
              {item.amount}원 - {item.status === 2 ? '결제완료' : '결제취소'}
            </s.Item>
          </s.ListItem>
        ))}
      {category === 'contact' &&
        items.map((item, idx) => (
          <s.ListItem
            key={idx}
            idx={idx}
            cur={index}
            onClick={() => onChangeItem(idx)}
          >
            <s.Top>
              {/* 이름 및 날짜 */}
              <s.Item>
                {item.id}. {item.name}
              </s.Item>
              <s.Item date>{format.dateToFormat(item.createdDate)}</s.Item>
            </s.Top>
            {/* 내용 */}
            <s.Item contact>{item.contactContent}</s.Item>
          </s.ListItem>
        ))}
      {category === 'examine' &&
        items.map((item, idx) => (
          <s.ListItem
            key={idx}
            idx={idx}
            cur={index}
            onClick={() => onChangeItem(idx)}
          >
            <s.Top>
              {/* 검수 및 날짜 */}
              <s.Item examine={item.isExamine}>
                {format.examineToFormat(item.isExamine)}
              </s.Item>
              <s.Item date>{format.dateToFormat(item.modifiedDate)}</s.Item>
            </s.Top>
            {/* 브랜치명 및 신청자 */}
            <s.Item branchName idx={idx} cur={index}>
              {item.branchName}
            </s.Item>
            <s.Item examine={1}>
              {item.user.userLastName + item.user.userFirstName}
            </s.Item>
          </s.ListItem>
        ))}
      {category === 'earning' &&
        // items == users
        items.map((item, idx) => (
          <s.ListItem
            key={idx}
            idx={idx}
            cur={index}
            onClick={() => onChangeItem(idx)}
          >
            <s.Top>
              {/* b2c -> 개인판매자, b2bc -> 개인사업자, b2bb -> 법인사업자 
                  item -> UserBasic 정보만 가지고있음 company 연관관계로 가져와야 접근가능*/}
              <s.Item>
                {item.userType === 'b2c'
                  ? '개인판매자'
                  : (item.userType === 'b2bc' ? '개인사업자' : '법인사업자') +
                    ' - ' +
                    (item.company && item.company.companyName)}
              </s.Item>
              <s.Item date>
                {/* 결산 미완료 개수 (status === 0) */}
                {/* 결산진행 중 개수 */}
                {/* 결산 완료 개수 */}
                {/* earning payStatus가 3인경우 제외를 해주어야한다 */}
                {/* {item.earnings && item.earnings.length > 0 ? `결산 미완료 개수: ${getCountByStatus(item.earnings, 0)}, 결산 요청중인 개수: ${getCountByStatus(item.earnings, 1)}, 결산 완료 개수: ${getCountByStatus(item.earnings, 2)}` : "결산할 데이터 없음"} */}
              </s.Item>
            </s.Top>
            <s.Item earning>{item.userLastName + item.userFirstName}</s.Item>
          </s.ListItem>
        ))}
      {/* 이벤트 */}
      {category === 'event' &&
        items.map((item, idx) => (
          <s.ListItem
            key={idx}
            idx={idx}
            cur={index}
            onClick={() => onChangeItem(idx)}
          >
            <s.Top>
              <s.Item>{item.title}</s.Item>
              <s.Item date>등록일 {format.dateToFormat(item.createdAt)}</s.Item>
            </s.Top>
            <s.Item event>
              이벤트 기간: &nbsp;
              {format.timeToFormat(item.startDate)}
              &nbsp; ~&nbsp;
              {format.dateToFormat(item.endDate)}
            </s.Item>
          </s.ListItem>
        ))}
      {/* 공지사항 */}
      {category === 'notice' &&
        items.map((item, idx) => (
          <s.ListItem
            key={idx}
            idx={idx}
            cur={index}
            onClick={() => onChangeItem(idx)}
          >
            <s.Top>
              <s.Item>{item.title}</s.Item>
              <s.Item date>등록일 {format.dateToFormat(item.createdAt)}</s.Item>
            </s.Top>
            <s.Item notice>
              공지사항 게시일시: &nbsp;
              {format.timeToFormat(item.startDate)}
            </s.Item>
          </s.ListItem>
        ))}
      {/* 퍼스널널 */}
      {category === 'personal' &&
        items.map((item, idx) => (
          <s.ListItem
            key={idx}
            idx={idx}
            cur={index}
            onClick={() => onChangeItem(idx)}
          >
            <s.Top>
              <s.Item>{item.title}</s.Item>
              <s.Item date>등록일 {format.dateToFormat(item.createdAt)}</s.Item>
            </s.Top>
            <s.Item notice>
              알림 게시일시: &nbsp;
              {format.timeToFormat(item.startDate)}
            </s.Item>
          </s.ListItem>
        ))}
    </s.ListContainer>
  );
};

export default ListPresenter;
