import styled from 'styled-components';
import * as styles from 'util/style/Styles';

export const TitleContainer = styled.div`
  font-size: 1.125rem;
  padding: ${styles.Padding};
  ${styles.FlexBetween};
`;
export const HighLight = styled.strong`
  font-weight: 600;
`;

// 필터 영역
export const FilterContainer = styled.div`
  ${styles.FlexBetween};
`;
export const StartDateFilterDiv = styled.div`
  flex: 3;
  margin: 5px;
  /* padding: 5px; */
  text-align: center;
  border: solid;
  border-radius: 1px;
  border-width: 1.5px;
  border-color: gray;
`;
export const EndDateFilterDiv = styled.div`
  flex: 3;
  margin: 5px;
  /* padding: 5px; */
  text-align: center;
  border: solid;
  border-radius: 1px;
  border-width: 1.5px;
  border-color: gray;
`;

// EarningRecord 조회 결과 영역
export const EarningRecordContainer = styled.div`
  scroll-behavior: smooth;
  height: 800px;
  overflow-y: scroll;
  margin: 10px;
  border: solid;
  border-radius: 1px;
  border-width: 3px;
  border-color: ${styles.MyzzymTheme.MyzzymColor};
`;

/**
 * 각 EarningRecord
 */
export const ItemContainer = styled.div`
  ${styles.FlexBetween};
  margin: 10px;
  padding: 10px;
  border: solid;
  border-radius: 1px;
  border-width: 3px;
  border-color: black;
`;
export const ItemId = styled.div`
  flex: 2;
  text-align: center;
`;
export const ItemEarningRange = styled.div`
  flex: 2;
  text-align: center;
`;
export const ItemRequestDate = styled.div`
  flex: 3;
  text-align: center;
`;
export const ItemDtPay = styled.div`
  flex: 3;
  text-align: center;
`;
export const ItemAmtSupply = styled.div`
  flex: 2;
  text-align: center;
`;
