import { api } from './api-base';

/*
 *
 * API
 *
 */
export const contactsApi = {
  /* 문의 페이지에서 조회 했을 때 나오는 문의 리스트 */
  contacts: () => api.get('/contacts'),
};
