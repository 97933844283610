/**
 * @author ydoni
 * @date 2021.07.20
 * @description 문의사항 내용
 */

import React from 'react';
import ContactContentPresenter from './ContactContentPresenter';

const ContactContentContainer = ({ item }) => {
  return <ContactContentPresenter item={item} />;
};

export default ContactContentContainer;
