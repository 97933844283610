/**
 * @author suyeon-jung
 * @date 2021.07.22
 * @description 공통 modal
 */
import React, { useRef, useEffect } from 'react';
import * as s from './ModalStyled';

const Modal = (props) => {
  const {
    show,
    changeShow,
    onClickFooterButton,
    footerButtonText,
    header,
    showImg,
    children,
  } = props;
  const modalEl = useRef();
  const handleClickOutside = (e) => {
    if (e.target !== modalEl.current) return;
    changeShow();
  };
  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    show && (
      <s.Modal show={show} ref={modalEl}>
        <s.ModalSection>
          <s.ModalHeader>
            {header}
            <s.ModalHeaderButton onClick={changeShow}>dd</s.ModalHeaderButton>
          </s.ModalHeader>
          <s.ModalBody showImg={showImg}>{children}</s.ModalBody>
          <s.ModalFooter>
            <s.ModalFooterButton onClick={onClickFooterButton}>
              {footerButtonText}
            </s.ModalFooterButton>
          </s.ModalFooter>
        </s.ModalSection>
      </s.Modal>
    )
  );
};

export default Modal;
