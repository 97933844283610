import { api } from './api-base';

const earningRecordApi = {
  /**
   * @GetMapping("/users/{userId}/earningRecords")
   */
  searchEarningRecordsByUser: (userId, params) =>
    api.get(`/users/${userId}/earningRecords`, {
      params: params,
    }),

  /**
   * @PutMapping("/earningRecords/{earningRecordId}/paymentsStatus")
   */
  updateEarningRecordPaymentsStatus: (earningRecordId, paymentsStatus) =>
    api.put(
      `/earningRecords/${earningRecordId}/paymentsStatus`,
      paymentsStatus,
    ),
};

export default earningRecordApi;
