/**
 * @author suyeon-jung
 * @date 2021.06.29
 * @description button component
 *              onClick(버튼 클릭 함수)
 */
import React from 'react';
import styled from 'styled-components';
import { MyzzymTheme } from 'util/style/Styles';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1%;
  margin-right: 2%;
`;
const Button = styled.button`
  background-color: ${MyzzymTheme.MyzzymColor};
  color: ${MyzzymTheme.White};
  width: 40px;
  height: 40px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 0;
  &:hover {
    background-color: ${MyzzymTheme.MyzzymFormColor};
  }
`;
export default ({ onClick, children }) => (
  <ButtonWrapper>
    <Button onClick={onClick}>{children}</Button>
  </ButtonWrapper>
);
