/**
 * @author suyeon-jung
 * @date 2021.06.29
 * @description
 * 1. 천 단위 콤마(사용자의 PC가 어떤 local이든지 ko-KR 기준으로 포맷팅)
 * 2. 전화번호(-)
 * 3. 미흡한 항목 텍스트 형태로 변환
 * 4. 날짜
 * 5. 검수 상태값
 * 6. 결산 상태값
 */

import moment from 'moment';

export const priceToFormat = (price) => price.toLocaleString('ko-KR');

export const telNumberFormatter = (telNumber) =>
  telNumber
    .replace(/[^0-9]/g, '')
    .replace(
      /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
      '$1-$2-$3',
    )
    .replace('--', '-');

const nameToText = {
  storageType: '창고 종류',
  branchName: '창고명',
  branchDescription: '창고소개',
  branchPhone: '전화번호',
  roadAddress: '창고주소',
  branchTime: '운영시간',
  branchWeb: '홈페이지',
  branchImage: '창고사진',
};
// 미흡한 항목 배열을 알림을 위한 텍스트 형태로 변환
export const checkListFormatter = (checkList) => {
  let temp = [];
  for (let i = 0; i < checkList.length; i++) {
    if (checkList[i].includes('unit')) {
      temp[i] = '유닛 ' + checkList[i].split(' ')[2];
    } else {
      temp[i] = nameToText[checkList[i]];
    }
  }
  return temp.join(', ') + ' ';
};

export const dateToFormat = (date) => moment(date).format('YYYY.M.D.');

export const timeToFormat = (date) => moment(date).format('YYYY.M.D. HH:mm:ss');

// 초기 시간값인지 확인
export const isInitialDate = (date) => moment(date).unix() === 0;

//검수
export const examineToFormat = (examine) => {
  switch (examine) {
    case 0:
      return '검수중';
    case 1:
      return '검수완료';
    case 2:
      return '검수반려';
    default:
      return null;
  }
};

// 결산
export const earningToFormat = (earning) => {
  switch (earning) {
    case 0:
      return '결산X / 지급X';
    case 1:
      return '결산O / 지급X';
    case 2:
      return '결산O / 지급O';
    case 3:
      return '결제취소';
    default:
      return null;
  }
};

export const payStatusToString = (payStatus) => {
  if (payStatus === 0) {
    return '지급 미완료';
  }
  if (payStatus === 1) {
    return '지급 요청중';
  }
  if (payStatus === 2) {
    return '지급 완료';
  }
  if (payStatus === 3) {
    return '예약취소됨';
  }
  return 'payStatus값 0~3 벗어남';
};
