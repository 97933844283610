import React from 'react';
import * as s from './RequestStyled';
import { priceToFormat } from 'util/fomatter/Formatter';
import moment from 'moment';

import Button from 'components/atoms/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const RequestPresenter = ({
  earningRecords,
  startDate,
  endDate,

  onChangeStartDate,
  onChangeEndDate,

  fetchData,
  executeFail,
  executeDelete,
  executeComplete,
}) => {
  return (
    <>
      <s.TitleContainer>
        <s.HighLight>
          지급요청 → 지급완료 or 지급요청취소 (지급일 기준 날짜 필터링)
        </s.HighLight>
      </s.TitleContainer>

      <s.FilterContainer>
        {/* 시작날짜 선택 */}
        <s.StartDateFilterDiv>
          <DatePicker
            selected={startDate}
            onChange={onChangeStartDate}
            dateFormat={'yyyy-MM-dd'}
          ></DatePicker>
          (지급일 기준 조회 시작일)
        </s.StartDateFilterDiv>

        {/* 끝날짜 선택 */}
        <s.EndDateFilterDiv>
          <DatePicker
            selected={endDate}
            onChange={onChangeEndDate}
            dateFormat={'yyyy-MM-dd'}
          ></DatePicker>
          (지급일 기준 조회 종료일)
        </s.EndDateFilterDiv>

        {/* 조회 버튼 */}
        <Button text={'조회'} onClick={fetchData}></Button>
      </s.FilterContainer>

      <s.EarningRecordContainer>
        <s.ItemContainer>
          {/* EarningRecord.id  */}
          <s.ItemId>결산기록(EarningRecord) No.</s.ItemId>
          {/* 결산기간 */}
          <s.ItemEarningRange>결산기간</s.ItemEarningRange>
          {/* 지급요청날짜 - EarningRecord.insertRequestDate */}
          <s.ItemRequestDate>지급요청 등록일</s.ItemRequestDate>
          {/* 지급일자(예정일) - EarningRecord.dtPay */}
          <s.ItemDtPay>지급일</s.ItemDtPay>
          {/* 지급액 - EarningRecord.amtSupply */}
          <s.ItemAmtSupply>지급액</s.ItemAmtSupply>

          {/* 삭제버튼 */}
          <Button text="OOO"></Button>
          {/* 지급완료버튼 */}
          <Button text="OOOO"></Button>
        </s.ItemContainer>
        !!!. 지급실패: 지급요청 완료후 잔액부족등의 이유로 인해 반려된경우 삭제가 불가능하여 booking, earning 상태를 되돌리기위함
        <br></br>
        !!!. 지급요청취소: 지급요청완료 후 이니시스에서 지급대행을 실행하기 전에 요청을 삭제하기 위함
        {earningRecords.map((earningRecord, index) => (
          <s.ItemContainer key={index}>
            {/* EarningRecord.id  */}
            <s.ItemId>{earningRecord.id}</s.ItemId>
            {/* 결산기간 */}
            <s.ItemEarningRange>
              {earningRecord.earningStartDate} ~ {earningRecord.earningEndDate}
            </s.ItemEarningRange>
            {/* 지급요청날짜 - EarningRecord.insertRequestDate */}
            <s.ItemRequestDate>
              {earningRecord.insertRequestDate}
            </s.ItemRequestDate>
            {/* 지급일자(예정일) - EarningRecord.dtPay */}
            <s.ItemDtPay>
              {moment(earningRecord.dtPay, 'YYYYMMDD').format('YYYY-MM-DD')}
            </s.ItemDtPay>
            {/* 지급액 - EarningRecord.amtSupply */}
            <s.ItemAmtSupply>
              {priceToFormat(parseInt(earningRecord.amtSupply))}
            </s.ItemAmtSupply>
            {/* 지급실패한 경우 버튼 */}
            <Button text="지급실패" onClick={() => executeFail(index)}></Button>
            {/* 삭제버튼 */}
            <Button text="지급요청취소" onClick={() => executeDelete(index)}></Button>
            {/* 지급완료버튼 */}
            <Button
              text="지급완료"
              onClick={() => executeComplete(index)}
            ></Button>
          </s.ItemContainer>
        ))}
      </s.EarningRecordContainer>
    </>
  );
};

export default RequestPresenter;
