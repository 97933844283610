import styled from 'styled-components';
import * as styles from 'util/style/Styles';

export const TitleContainer = styled.div`
  font-size: 1.125rem;
  padding: ${styles.Padding};
  ${styles.FlexBetween};
`;

export const Title = styled.div`
  flex: 10;
`;

export const HighLight = styled.strong`
  font-weight: 600;
`;

//branch 정보
export const BranchContainer = styled.ul`
  width: 50%;
  overflow-y: scroll;
  border-right: ${(props) => (props.unit ? 0 : styles.Border)};
`;

export const BranchItem = styled.li`
  ${styles.FlexStart};
  padding: ${styles.Padding};
`;

export const BranchItemTitle = styled.span`
  flex: 1;
  color: ${styles.MyzzymTheme.Gray};
`;

export const BranchItemLabel = styled.label`
  flex: 3;
  display: flex;
  ${styles.FlexBetween};
`;

export const BranchItemDesc = styled.span`
  flex: 6;
`;
