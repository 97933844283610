import React from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { MyzzymTheme } from 'util/style/Styles';

const Container = styled.div`
  min-height: calc(100% - 380px);
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export default () => (
  <Container>
    <Loader
      type="Oval"
      color={MyzzymTheme.MyzzymColor}
      height={80}
      width={80}
      timeout={10000} //3 secs
    />
  </Container>
);
