import React, { useEffect, useState } from 'react';

import earningRecordApi from 'api/api-earningRecord';
import { payApi } from 'api/api-pay';

import RequestPresenter from './RequestPresenter';
import moment from 'moment';

/**
 * 지급요청 상태 - 날짜기간에 EarningRecord.insertRequestDate가 포함된 EarningRecord 조회
 * !!. Branch는 상관없음
 */
const RequestContainer = ({ curUserIndex, user }) => {
  const [earningRecords, setEarningRecords] = useState([]);

  /**
   * 날짜 필터
   */
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChangeStartDate = (date) => {
    setStartDate(date);
  };
  const onChangeEndDate = (date) => {
    setEndDate(date);
  };

  // EarningRecode 조회
  const fetchData = async () => {
    let params = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      paymentsStatus: 'INSERTREQUEST',
    };

    let result = null;
    try {
      ({ data: result } = await earningRecordApi.searchEarningRecordsByUser(
        user.id,
        params,
      ));
      setEarningRecords(result);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 지급 요청 완료 후 이니시스 측에서 '잔액부족' 등으로 인한 사유로 반려된 경우 등등
   * -> booking, earning status 업데이트 된 상태만 다시 되돌려놓기
   */
  const executeFail = async (index) => {
    let confirm = window.confirm('지급실패를 진행하시겠습니까?');
    if (!confirm) {
      return;
    }
    try {
      const { data: result } = await payApi.failPayments(
        earningRecords[index].id,
      );
      // result -> "success"
      if (result === 'success') {
        alert('지급실패 처리가 완료되었습니다.');
        // 완료 후 earningRecords
        let tempEarningRecords = earningRecords.filter(
          (earningRecord, idx) => idx !== index,
        );
        setEarningRecords(tempEarningRecords);
      } else {
        alert('지급실패 처리에 문제가 발생하였습니다.');
      }
    } catch (error) {
      console.log(error);
      alert('문제가 발생하였습니다.');
    }
  };

  /**
   * 각 EarningRecord에 대하여 삭제 버튼 누를 시 -> 이니시스 api 콜해야함
   */
  const executeDelete = async (index) => {
    let confirm = window.confirm('지급요청을 삭제하시겠습니까?');
    if (!confirm) {
      return;
    }
    try {
      const { data: result } = await payApi.deletePayments(
        earningRecords[index].id,
      );
      // result -> "success" or "fail"
      if (result === 'success') {
        alert('지급요청 삭제가 완료되었습니다.');
        // 완료 후 earningRecords
        let tempEarningRecords = earningRecords.filter(
          (earningRecord, idx) => idx !== index,
        );
        setEarningRecords(tempEarningRecords);
      } else {
        alert('지급요청 삭제에 문제가 발생하였습니다.');
      }
    } catch (error) {
      console.log(error);
      alert('문제가 발생하였습니다.');
    }
  };

  /**
   * 각 EarningRecord에 대하여 지급완료 버튼 누를 시
   */
  const executeComplete = async (index) => {
    let confirm = window.confirm('지급완료를 진행하시겠습니까?');
    if (!confirm) {
      return;
    }
    try {
      let paymentsStatus = {
        paymentsStatus: 'INSERTCOMPLETE',
      };
      const {
        data: result,
      } = await earningRecordApi.updateEarningRecordPaymentsStatus(
        earningRecords[index].id,
        paymentsStatus,
      );
      // result -> "success" or "fail"
      if (result === 'success') {
        alert('지급상태가 지급완료로 변경되었습니다.');
        // 완료 후 earningRecords 업데이트 해주기 완료한거 제거
        let tempEarningRecords = earningRecords.filter(
          (earningRecord, idx) => idx !== index,
        );
        setEarningRecords(tempEarningRecords);
      } else {
        alert('지급상태를 변경하는데에 문제가 실패하였습니다.');
      }
    } catch (error) {
      console.log(error);
      alert('문제가 발생하였습니다.');
    }
  };

  /**
   * User 변경 시 초기화
   */
  useEffect(() => {
    setEarningRecords([]);
    setStartDate(new Date());
    setEndDate(new Date());
  }, [curUserIndex]);

  return (
    <>
      <RequestPresenter
        earningRecords={earningRecords}
        startDate={startDate}
        endDate={endDate}
        onChangeStartDate={onChangeStartDate}
        onChangeEndDate={onChangeEndDate}
        fetchData={fetchData}
        executeFail={executeFail}
        executeDelete={executeDelete}
        executeComplete={executeComplete}
      ></RequestPresenter>
    </>
  );
};

export default RequestContainer;
