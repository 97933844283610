/**
 *
 * @author ydoni
 * @date 2021.07.20
 * @description side list
 */

import React from 'react';
import ListPresenter from './ListPresenter';

const ListContainer = ({ category, items, onChangeItem, index }) => {
  return (
    <ListPresenter
      category={category}
      items={items}
      onChangeItem={onChangeItem}
      index={index}
    ></ListPresenter>
  );
};

export default ListContainer;
