/**
 * @author suyeon-jung
 * @date 2021.06.29
 * @description button component
 *              text(버튼 내부 텍스트)
 *              onClick(버튼 클릭 함수)
 *              align(버튼 정렬)
 */
import React from 'react';
import styled from 'styled-components';
import { MyzzymTheme } from 'util/style/Styles';

const ButtonWrapper = styled.div`
  text-align: ${(props) => (props.align ? props.align : 'right')};
  margin: 0 0.5%;
`;
const Button = styled.button`
  background-color: ${(props) =>
    props.color ? props.color : MyzzymTheme.MyzzymColor};
  color: ${MyzzymTheme.White};
  font-size: 0.813rem;
  font-weight: 400;
`;

export default ({ text, onClick, align, color }) => (
  <ButtonWrapper align={align}>
    <Button onClick={onClick} color={color}>
      {text}
    </Button>
  </ButtonWrapper>
);
