import styled from 'styled-components';
import * as styles from 'util/style/Styles';

export const Container = styled.div`
  ${styles.FlexStart};
  flex-direction: column;
  width: 30%;
  height: 100%;
  border-right: 1px solid ${styles.MyzzymTheme.GrayBorder};
`;

// type
export const TypeContainer = styled.ul`
  ${styles.Flex};
  width: 100%;
  border-bottom: 1px solid ${styles.MyzzymTheme.GrayBorder};
`;

export const TypeItem = styled.li`
  ${styles.Flex};
  width: 100%;
  height: 3.125rem;
  color: ${(props) =>
    props.selectedFilter === props.filter
      ? styles.MyzzymTheme.MyzzymColor
      : styles.MyzzymTheme.Gray};
  background-color: ${(props) =>
    props.selectedFilter === props.filter &&
    styles.MyzzymTheme.LightMyzzymColor};
  cursor: pointer;

  &:nth-child(2) {
    border-left: 1px solid ${styles.MyzzymTheme.GrayBorder};
    border-right: 1px solid ${styles.MyzzymTheme.GrayBorder};
  }
  &:hover {
    background-color: ${styles.MyzzymTheme.LightMyzzymColor};
  }
`;

//list
export const ListContainer = styled.ul`
  width: 100%;
  height: ${window.innerHeight - 146}px;
  overflow-y: scroll;
`;

export const ListItem = styled.li`
  border-bottom: 1px solid ${styles.MyzzymTheme.GrayBorder};
  /* border: ${(props) =>
    props.idx === props.cur && '1px solid' + styles.MyzzymTheme.MyzzymColor}; */
  padding: 15px;
  cursor: pointer;
  background-color: ${(props) =>
    props.idx === props.cur && styles.MyzzymTheme.LightMyzzymColor};
  box-sizing: border-box;
  &:hover {
    border: 1px solid ${styles.MyzzymTheme.MyzzymColor};
  }
`;

export const Top = styled.div`
  ${styles.FlexBetween};
`;

export const Item = styled.p`
  font-size: ${(props) => (props.date ? '0.75rem' : '0.938rem')};
  color: ${styles.MyzzymTheme.Black};
  ${(props) => props.examine === 0 && `color : ${styles.MyzzymTheme.Red}`};
  ${(props) =>
    (props.examine === 2 || props.date) &&
    `color : ${styles.MyzzymTheme.Gray}`};
  ${(props) => props.branchName && `padding : 10px 0`};
  ${(props) => props.contact && `padding-top : 10px`};
  ${(props) => props.earning && `padding-top : 10px`};
  ${(props) =>
    props.event &&
    `padding-top : 10px; font-size: 0.825rem; color : ${styles.MyzzymTheme.Gray}`}
  ${(props) =>
    props.notice &&
    `padding-top : 10px; font-size: 0.825rem; color : ${styles.MyzzymTheme.Gray}`}
`;
