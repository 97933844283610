import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MyzzymTheme } from 'util/style/Styles';

const Container = styled.div`
  /* padding-bottom: 20px; */
  /* font-size: 1.4em; */
  /* position: absolute; */
  width: max-content;
  font-weight: 600;
  margin: 30px auto;
  /* display: flex; */
  /* left: 50%;
  transform: translateX(-50%); */
`;

const Image = styled.div`
  width: ${(props) => (props.width ? '250px' : '100px')};
  height: ${(props) => (props.height ? '230px' : '100px')};
  background: url(${(props) => props.bgUrl}) no-repeat center;
  background-size: cover;
  margin: 0 auto;
`;

const Text = styled.p`
  color: ${MyzzymTheme.MyzzymColor};
  font-weight: 400;
  text-align: center;
  font-size: 20px;
  margin-top: 30px;
`;

const Message = ({ width, height, bgUrl, text }) => (
  <Container>
    <Image width={width} height={height} bgUrl={bgUrl}></Image>
    <Text>{text}</Text>
  </Container>
);

Message.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default Message;
