import React from 'react';
import * as s from './EventContentStyled';
import Content from 'components/molecules/content/Content';
import NotificationAddButton from 'views/myzzym/Notification/NotificationAddButton';
import NotificationDeleteButton from 'views/myzzym/Notification/NotificationDeleteButton';
import NotificationReserveButton from 'views/myzzym/Notification/NotificationReserveButton';
import * as format from 'util/fomatter/Formatter';

const EventContentPresenter = ({ item, pathname }) => {
  return (
    <Content>
      <s.TitleContainer>
        <s.Title>
          <s.HighLight>{item?.title}</s.HighLight>
        </s.Title>
        <NotificationReserveButton item={item} />
        <NotificationDeleteButton item={item} pathname={pathname} />
        <NotificationAddButton pathname={pathname} />
      </s.TitleContainer>
      {item?.images &&
        item?.images.map((image, idx) => (
          <s.EventImage
            key={idx}
            src={image}
            alt=""
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        ))}
      <s.EventItem>
        <s.EventItemTitle>category</s.EventItemTitle>
        <s.EventItemDesc>{item?.category}</s.EventItemDesc>
      </s.EventItem>
      <s.EventItem>
        <s.EventItemTitle>이벤트 설명</s.EventItemTitle>
        {/* <s.EventItemDesc>{item?.desc}</s.EventItemDesc> */}
        <s.EventItemDesc>
          <div dangerouslySetInnerHTML={{ __html: `${item?.desc}` }} />
        </s.EventItemDesc>
      </s.EventItem>
      <s.EventItem>
        <s.EventItemTitle>이벤트 기간</s.EventItemTitle>
        <s.EventItemDesc>
          {item?.startDate && format.dateToFormat(item.startDate)}
          &nbsp;~&nbsp;&nbsp;
          {item?.endDate && format.dateToFormat(item.endDate)}
        </s.EventItemDesc>
      </s.EventItem>
      <s.EventItem>
        <s.EventItemTitle>이벤트 게시일시</s.EventItemTitle>
        <s.EventItemDesc>
          {item?.startDate && format.timeToFormat(item.startDate)}
        </s.EventItemDesc>
      </s.EventItem>
      <s.EventItem>
        <s.EventItemTitle>푸시 일시</s.EventItemTitle>
        <s.EventItemDesc>
          {item?.pushDate && format.isInitialDate(item.pushDate)
            ? '설정 안됨'
            : item?.pushDate && format.timeToFormat(item.pushDate)}
        </s.EventItemDesc>
      </s.EventItem>
      {/* <s.EventItem>
        <s.EventItemTitle>푸시 상태</s.EventItemTitle>
        <s.EventItemDesc>{item.hasSent ? "푸시완료" : "미전송"}</s.EventItemDesc>
      </s.EventItem> */}
      <s.EventItem>
        <s.EventItemTitle>작성자</s.EventItemTitle>
        <s.EventItemDesc>{item?.writer}</s.EventItemDesc>
      </s.EventItem>
      <s.EventItem>
        <s.EventItemTitle>이벤트 등록일시</s.EventItemTitle>
        <s.EventItemDesc>
          {item?.createdAt && format.timeToFormat(item.createdAt)}
        </s.EventItemDesc>
      </s.EventItem>
    </Content>
  );
};

export default EventContentPresenter;
