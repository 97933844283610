import React from 'react';
import BookingSearchPresenter from './BookingSearchPresenter';
import { bookingSearchOptions } from './BookingSearchOptions';

const BookingSearchContainer = ({ setSearchParams }) => {
  const searchKeys = bookingSearchOptions.map(
    (searchOption) => searchOption.key,
  );
  const onSearchBy = (searchKey, value) => {
    const searchParams = { key: searchKey, value: value };
    setSearchParams(searchParams);
  };
  return (
    <BookingSearchPresenter onSearchBy={onSearchBy} searchKeys={searchKeys} />
  );
};

export default BookingSearchContainer;
