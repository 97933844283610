/*********************************
//*** @Author : hanbin ***
//*** @Date : 2020.09.21 ***
//*** @Title : ContactPresenter ***
*********************************/

import React from 'react';
import * as s from './ContactStyled';
import DefaultContainer from 'components/DefaultContainer';
import Loader from 'components/atoms/Loader';
import Error from 'components/molecules/Error';
import { telNumberFormatter } from 'util/fomatter/Formatter';
import ENV from 'config/config';

const ContactPresenter = ({
  contactList,
  isLoading,
  isError,
  onDeleteIds,
  onClickCheckBox,
}) => {
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <DefaultContainer>
          <s.OuterContainer>
            <s.Title>문의 내역</s.Title>
            <s.SubTitle>
              현재 총 {contactList.length}개의 문의 내역이 있습니다.
            </s.SubTitle>
            <s.DeleteBtn onClick={onDeleteIds}>삭제하기</s.DeleteBtn>
            {isError ? (
              <Error
                text="데이터를 불러오는데 실패하였습니다."
                color="#1dc1bb"
                bgUrl={`${ENV.CDN_IMAGE_PATH}/icon/myzzym_nonexist.png`}
              />
            ) : (
              <s.Wrapper>
                {contactList.length === 0 ? (
                  <Error
                    text="문의 내역이 존재하지 않습니다"
                    color="#1dc1bb"
                    bgUrl={`${ENV.CDN_IMAGE_PATH}/icon/myzzym_nonexist.png`}
                  />
                ) : (
                  <s.ContactList>
                    {contactList.map((contact) => (
                      <s.ContactContent key={contact.id}>
                        <s.CheckBox
                          type="checkbox"
                          onClick={() => onClickCheckBox(contact.id)}
                        />
                        <s.ContentWrapper>
                          <s.NameWrapper>
                            {contact.schoolName} | {contact.chargerName}
                          </s.NameWrapper>
                          <s.ContactText>
                            전화번호 : {telNumberFormatter(contact.phoneNum)}
                          </s.ContactText>
                          <s.ContactText>
                            이메일 : {contact.emailAddress}
                          </s.ContactText>
                        </s.ContentWrapper>
                      </s.ContactContent>
                    ))}
                  </s.ContactList>
                )}
              </s.Wrapper>
            )}
          </s.OuterContainer>
        </DefaultContainer>
      )}
    </>
  );
};

export default ContactPresenter;
