/**
 * @author dong
 * @date 2021.08.02
 * @description 결산 탭 - 정보등록 탭- 이니시스 지급대행서비스 정보등록 페이지
 */
import React, { useEffect, useState } from 'react';
import * as s from './EarningContentStyled';
import * as styles from 'util/style/Styles';
import Button from 'components/atoms/Button';

import { payApi } from 'api/api-pay';

export default ({ user }) => {
  console.log(user);

  // 판매자 ID
  const [idMall, setIdMall] = useState('');
  // 판매자 유형 -> // 1: main mall, 2: sub mall -> 일단 기본적으로 모두 2로 backend로 보내기 -> 필요한경우 변경
  const [clId, setClId] = useState('2');
  // 사업자 번호 -> 개인판매자는 없음
  const [noComp, setNoComp] = useState(
    user.company ? user.company.companyNum : '',
  );
  // 상호
  const [nmComp, setNmComp] = useState(
    user.company
      ? user.company.companyName
      : user.userLastName + user.userFirstName,
  );
  // 사업주명
  const [nmBoss, setNmBoss] = useState(user.userLastName + user.userFirstName);
  // 계좌성명
  const [nmRegist, setNmRegist] = useState(user.accountName);
  // 은행코드
  const [cdBank, setCdBank] = useState(user.accountBank);
  // 계좌번호
  const [noAcct, setNoAcct] = useState(user.accountNum);
  // 전화번호
  const [noTel, setNoTel] = useState(user.userTel);
  // 요청구분 -> 1: 등록 / 2: 갱신
  const [clGubun, setClGubun] = useState('1');

  const handleChange = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    if (e.target.name === 'idMall') {
      setIdMall(e.target.value);
    } else if (e.target.name === 'noComp') {
      setNoComp(e.target.value);
    } else if (e.target.name === 'nmComp') {
      setNmComp(e.target.value);
    } else if (e.target.name === 'nmBoss') {
      setNmBoss(e.target.value);
    } else if (e.target.name === 'nmRegist') {
      setNmRegist(e.target.value);
    } else if (e.target.name === 'cdBank') {
      setCdBank(e.target.value);
    } else if (e.target.name === 'noAcct') {
      setNoAcct(e.target.value);
    } else if (e.target.name === 'noTel') {
      setNoTel(e.target.value);
    } else if (e.target.name === 'clGubun') {
      if (e.target.value === 'register') {
        setClGubun('1');
      } else {
        setClGubun('2');
      }
    }
  };

  /**
   * 정보등록 완료 버튼
   * 1. Backend로 mall 정보 전송
   * 2. Backend에서 이니시스 지급대행 정보등록 요청
   */
  const executeRegister = async () => {
    // 필수 값 체크
    if (idMall === '' || nmRegist === '' || cdBank === '' || noAcct === '') {
      alert('필수값을 채워주세요');
      return;
    }
    // 이미 서브몰 등록이 되어있는 경우 == idMall이 있는 경우 처리해주기
    // if (user.idMall) {
    //     alert("이미 등록되어있는 정보입니다. 갱신을 이용해주세요");
    //     return;
    // }
    // confirm -> true or false
    let confirm = window.confirm('등록(갱신)하시겠습니까?');
    if (confirm) {
      // 0. mall 정보 객체로 생성
      let mall = {
        idMall: idMall,
        clId: clId,
        noComp: noComp,
        nmComp: nmComp,
        nmBoss: nmBoss,
        nmRegist: nmRegist,
        cdBank: cdBank,
        noAcct: noAcct,
        noTel: noTel,
        clGubun: clGubun,
      };
      let result = null;
      // 1. Backend API 호출
      try {
        result = await payApi.registerMall(user.id, mall);
        alert('정상처리되었습니다.');
        window.location.reload();
      } catch (error) {
        let errorResponse = error.response;
        if (errorResponse) {
          alert('에러가 발생하였습니다.' + errorResponse.data);
        } else {
          // api 호출에 대해 서버 에러가 아닌 다른 이유의 에러인겨웅 -> ex. 네트워크 에러
          alert('에러가 발생하였습니다.');
        }
      }

      console.log('확인');
    } else {
      console.log('취소');
    }
  };

  useEffect(() => {
    setIdMall(user.idMall ? user.idMall : '');
    setNoComp(user.company ? user.company.companyNum : '');
    setNmComp(
      user.company
        ? user.company.companyName
        : user.userLastName + user.userFirstName,
    );
    setNmBoss(user.userLastName + user.userFirstName);
    setNmRegist(user.accountName ? user.accountName : '');
    setCdBank(user.accountBank ? user.accountBank : '');
    setNoAcct(user.accountNum ? user.accountNum : '');
    setNoTel(user.userTel);
    setClGubun('1');
  }, [user]);

  return (
    <>
      <s.TitleContainer>
        <s.Title>
          <s.HighLight>
            정보 등록 - 지급대행서비스를 위하여 해당 User에 관한 mall 정보를
            등록 (*는 필수)
          </s.HighLight>
        </s.Title>
      </s.TitleContainer>
      {/* 정보등록을 위한 input 데이터들 */}
      <s.EarningItemContainer>
        <s.EarningInfoKey>* 판매자 ID</s.EarningInfoKey>
        <s.EarningInfoValue>
          <s.EarningInfoValueInput
            name="idMall"
            placeholder="test"
            value={idMall}
            onChange={handleChange}
          ></s.EarningInfoValueInput>
        </s.EarningInfoValue>
      </s.EarningItemContainer>

      <s.EarningItemContainer>
        <s.EarningInfoKey>
          사업자번호(개인판매자는 없어도됨) - 고정값
        </s.EarningInfoKey>
        <s.EarningInfoValue>
          <s.EarningInfoValueInput
            disabled={true}
            name="noComp"
            placeholder="test"
            value={noComp}
            onChange={handleChange}
          ></s.EarningInfoValueInput>
        </s.EarningInfoValue>
      </s.EarningItemContainer>

      <s.EarningItemContainer>
        <s.EarningInfoKey>
          * 상호명(개인판매자는 사용자 이름) - 고정값
        </s.EarningInfoKey>
        <s.EarningInfoValue>
          {/* <s.EarningInfoValueInput disabled={true} name={nmComp} placeholder="test" value={nmComp} onChange={handleChange}></s.EarningInfoValueInput> */}
          <s.EarningInfoValueInput
            name="nmComp"
            placeholder="test"
            value={nmComp}
            onChange={handleChange}
          ></s.EarningInfoValueInput>
        </s.EarningInfoValue>
      </s.EarningItemContainer>

      <s.EarningItemContainer>
        <s.EarningInfoKey>* 사업주명(기본 User 이름)</s.EarningInfoKey>
        <s.EarningInfoValue>
          {/* <s.EarningInfoValueInput disabled={true} name={nmBoss} placeholder="test" value={nmBoss} onChange={handleChange}></s.EarningInfoValueInput> */}
          <s.EarningInfoValueInput
            name="nmBoss"
            placeholder="test"
            value={nmBoss}
            onChange={handleChange}
          ></s.EarningInfoValueInput>
        </s.EarningInfoValue>
      </s.EarningItemContainer>

      <s.EarningItemContainer>
        <s.EarningInfoKey>* 계좌성명</s.EarningInfoKey>
        <s.EarningInfoValue>
          {/* <s.EarningInfoValueInput disabled={true} name={nmRegist} placeholder="test" value={nmRegist} onChange={handleChange}></s.EarningInfoValueInput> */}
          <s.EarningInfoValueInput
            name="nmRegist"
            placeholder="test"
            value={nmRegist}
            onChange={handleChange}
          ></s.EarningInfoValueInput>
        </s.EarningInfoValue>
      </s.EarningItemContainer>

      <s.EarningItemContainer>
        <s.EarningInfoKey>* 은행</s.EarningInfoKey>
        <s.EarningInfoValue>
          {/* <s.EarningInfoValueInput disabled={true} name={cdBank} placeholder="test" value={cdBank} onChange={handleChange}></s.EarningInfoValueInput> */}
          <s.EarningInfoValueInput
            name="cdBank"
            placeholder="test"
            value={cdBank}
            onChange={handleChange}
          ></s.EarningInfoValueInput>
        </s.EarningInfoValue>
      </s.EarningItemContainer>

      <s.EarningItemContainer>
        <s.EarningInfoKey>* 계좌번호</s.EarningInfoKey>
        <s.EarningInfoValue>
          {/* <s.EarningInfoValueInput disabled={true} name={noAcct} placeholder="test" value={noAcct} onChange={handleChange}></s.EarningInfoValueInput> */}
          <s.EarningInfoValueInput
            name="noAcct"
            placeholder="test"
            value={noAcct}
            onChange={handleChange}
          ></s.EarningInfoValueInput>
        </s.EarningInfoValue>
      </s.EarningItemContainer>

      <s.EarningItemContainer>
        <s.EarningInfoKey>* 전화번호 - 고정값</s.EarningInfoKey>
        <s.EarningInfoValue>
          {/* <s.EarningInfoValueInput disabled={true} name={noTel} placeholder="test" value={noTel} onChange={handleChange}></s.EarningInfoValueInput> */}
          <s.EarningInfoValueInput
            disabled={true}
            name="noTel"
            placeholder="test"
            value={noTel}
            onChange={handleChange}
          ></s.EarningInfoValueInput>
        </s.EarningInfoValue>
      </s.EarningItemContainer>

      <s.EarningItemContainer>
        <s.EarningInfoKey>등록 / 갱신</s.EarningInfoKey>
        <s.EarningInfoValue>
          {/* <s.EarningInfoValueInput  value={clGubun}></s.EarningInfoValueInput> */}
          <styles.Checkbox
            id="register"
            value="register"
            name="clGubun"
            type="radio"
            onChange={handleChange}
            checked={clGubun === '1'}
          ></styles.Checkbox>
          <label htmlFor="register">등록</label>
          {'         /       '}
          <styles.Checkbox
            id="update"
            value="update"
            name="clGubun"
            type="radio"
            onChange={handleChange}
            checked={clGubun === '2'}
          ></styles.Checkbox>
          <label htmlFor="update">갱신</label>
          {/* <s.EarningInfoValueInput placeholder="test" value={clGubun}></s.EarningInfoValueInput> */}
        </s.EarningInfoValue>
      </s.EarningItemContainer>

      {/* 등록 버튼  */}
      <Button text="완료" onClick={executeRegister}></Button>
    </>
  );
};
