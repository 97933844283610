/**
 * @author suyeon-jung
 * @date 2021.07.21
 * @description 추가 버튼(for 이벤트 및 공지사항)
 */
import React, { useState } from 'react';
import Form from 'views/myzzym/Notification/Form/add';
import CircleButton from 'components/atoms/CircleButton';
import { BsPlus } from 'react-icons/bs';

const NotificationAddButton = ({ pathname }) => {
  const [showModal, setShowModal] = useState(false);
  const changeShowModal = () => setShowModal(!showModal);
  return (
    <>
      <CircleButton onClick={changeShowModal}>
        <BsPlus size={25} style={{ margin: 0, padding: 0 }} />
      </CircleButton>
      {showModal && (
        <Form
          changeShowModal={changeShowModal}
          formTitle={
            pathname === '/myzzym/notice'
              ? '공지사항'
              : pathname === '/myzzym/event'
              ? '이벤트'
              : '퍼스널'
          }
        />
      )}
    </>
  );
};

export default NotificationAddButton;
