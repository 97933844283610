/**
 * @author suyeon-jung
 * @date 2021.06.25
 * @description 이벤트 및 공지사항 추가를 위한 폼
 */
import React from 'react';
import * as s from '../FormStyled';
import moment from 'moment';
import Loader from 'components/atoms/Loader';
import Modal from 'components/molecules/modal/Modal';
import { BsDash } from 'react-icons/bs';

const AddFormPresenter = ({
  form,
  isImgLoading,
  onChange,
  // getImgUrl,
  createNotification,
  formTitle,
  changeShowModal,
}) => {
  const { title, desc, startDate, endDate, userIds = '', category } = form;
  return (
    <Modal
      show={true}
      changeShow={changeShowModal}
      header={`${formTitle} 추가!`}
      // onClickFooterButton={() => {
      //   getImgUrl()
      //     .then((images) => createNotification(images))
      //     .catch(console.error);
      // }}
      onClickFooterButton={() => createNotification()}
      footerButtonText={'등록하기!'}
    >
      <s.Form>
        <s.FormExtra>
          추가된 {formTitle} 알림은 게시일 이후 사용자에게 전달됩니다.{<br />}
          {
            <span style={{ color: 'red' }}>
              !!주의!! <br /> 게시일시는 현재 이후로, 푸시일시는 게시일시 이후로
              등록해야 정상 동작합니다.
            </span>
          }
        </s.FormExtra>
        <s.InputWrapper>
          제목
          <s.Emphasis>*</s.Emphasis>
          <s.FormTitle
            name="title"
            placeholder="제목을 입력해주세요"
            onChange={onChange}
            value={title}
          ></s.FormTitle>
        </s.InputWrapper>

        <s.InputWrapper>
          설명
          <s.Emphasis>*</s.Emphasis>
          <s.FormDesc
            name="desc"
            placeholder="설명을 입력해주세요"
            onChange={onChange}
            value={desc}
          ></s.FormDesc>
        </s.InputWrapper>

        <s.InputWrapper>
          사진<s.Emphasis></s.Emphasis>
          <input
            type="file"
            name="imageList"
            accept="image/*"
            onChange={onChange}
            style={{ marginLeft: '2%' }}
            multiple
            disabled
          />
          {isImgLoading ? <Loader /> : null}
        </s.InputWrapper>

        <s.InputWrapper>
          {formTitle === '공지사항' ? (
            <>
              게시일<s.Emphasis>*</s.Emphasis>
              <s.DateWrapper>
                <s.DateSelect
                  placeholder="게시일"
                  type="datetime-local"
                  name="startDate"
                  value={moment(startDate).format('YYYY-MM-DDTHH:mm')}
                  onChange={onChange}
                  required
                />
              </s.DateWrapper>
            </>
          ) : formTitle === '이벤트' ? (
            <>
              기간
              <s.Emphasis>*</s.Emphasis>
              <s.DateWrapper>
                <s.DateSelect
                  placeholder="시작일"
                  type="datetime-local"
                  name="startDate"
                  value={moment(startDate).format('YYYY-MM-DDTHH:mm')}
                  onChange={onChange}
                  required
                />
              </s.DateWrapper>
              &nbsp;
              <BsDash />
              <s.DateWrapper>
                <s.DateSelect
                  type="date"
                  // type="datetime-local"
                  name="endDate"
                  value={moment(endDate).format('YYYY-MM-DD')}
                  // value={moment(endDate).format("YYYY-MM-DDTHH:mm")}
                  onChange={onChange}
                  required
                />
              </s.DateWrapper>
            </>
          ) : (
            <>
              게시일<s.Emphasis>*</s.Emphasis>
              <s.DateWrapper>
                <s.DateSelect
                  placeholder="게시일"
                  type="datetime-local"
                  name="startDate"
                  value={moment(startDate).format('YYYY-MM-DDTHH:mm')}
                  onChange={onChange}
                  required
                />
              </s.DateWrapper>
            </>
          )}
        </s.InputWrapper>
        {formTitle === '퍼스널' && (
          <>
            <s.InputDesc>
              userIds를 입력하지 않으면 아래 category에 동의한 모든 사용자에게
              전달됩니다.
            </s.InputDesc>
            <s.InputWrapper>
              userIds
              <s.Emphasis></s.Emphasis>
              <s.FormTitle
                name="userIds"
                placeholder=" userIds 를 입력하세요. 복수인 경우 콤마(,)로 구별합니다."
                onChange={onChange}
                value={userIds}
                required
              ></s.FormTitle>
            </s.InputWrapper>
            <s.InputWrapper>
              category
              <s.Emphasis>*</s.Emphasis>
              <s.FormTitle
                type="radio"
                name="category"
                onChange={onChange}
                value={'BOOKING'}
                checked={category === 'BOOKING'}
              />
              <label>BOOKING</label>
              <s.FormTitle
                type="radio"
                name="category"
                onChange={onChange}
                value={'MESSAGE'}
                checked={category === 'MESSAGE'}
              />
              <label>MESSAGE</label>
              <s.FormTitle
                type="radio"
                name="category"
                onChange={onChange}
                value={'ACTIVITY'}
                checked={category === 'ACTIVITY'}
              />
              <label>ACTIVITY</label>
              <s.FormTitle
                type="radio"
                name="category"
                onChange={onChange}
                value={'MARKETING'}
                checked={category === 'MARKETING'}
              />
              <label>MARKETING</label>
            </s.InputWrapper>
          </>
        )}
      </s.Form>
    </Modal>
  );
};

export default AddFormPresenter;
