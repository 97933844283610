import React, { useState, useEffect } from 'react';
import PersonalPresenter from './PersonalPresenter';
import moment from 'moment';

const PersonalContainer = ({ location: { pathname } }) => {
  const [eventList, setEventList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchData = async () => {
    // notification 서버에서 이벤트 조회했었지만 notification 서버 제거로 인해 현재 불러오는 데이 없음
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PersonalPresenter
      eventList={eventList}
      isLoading={isLoading}
      isError={isError}
      pathname={pathname}
    />
  );
};

export default PersonalContainer;
