import { api } from 'api/api-base';

const locationApi = {
  /**
   * @GetMapping("/locations/info") - 주소로 자세한 주소 및 위도경도 조회
   */
  searchInfo: (params) =>
    api.get(`/locations/info`, {
      params: params,
    }),
};

export default locationApi;
