/**
 * @author suyeon-jung
 * @date 2021.06.21
 * @description 이벤트
 */
import React from 'react';
import DefaultContainer from 'components/DefaultContainer';
import Loader from 'components/atoms/Loader';
import Error from 'components/molecules/Error';
import { MyzzymTheme } from 'util/style/Styles';
import ENV from 'config/config';
import EventDetail from './detail';
import NotificationAddButton from 'views/myzzym/Notification/NotificationAddButton';

const EventPresenter = ({ eventList, isLoading, isError, pathname }) => {
  return (
    <DefaultContainer>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Error
          text="데이터를 불러오는데 실패하였습니다."
          color={MyzzymTheme.MyzzymColor}
          bgUrl={`${ENV.CDN_IMAGE_PATH}/icon/myzzym_nonexist.png`}
        />
      ) : eventList.length === 0 ? (
        <>
          <NotificationAddButton pathname={pathname} />
          <Error
            text="이벤트가 존재하지 않습니다."
            color={MyzzymTheme.MyzzymColor}
            bgUrl={`${ENV.CDN_IMAGE_PATH}/icon/myzzym_nonexist.png`}
          />
        </>
      ) : (
        <EventDetail items={eventList} pathname={pathname} />
      )}
    </DefaultContainer>
  );
};

export default EventPresenter;
