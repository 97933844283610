/**
 * @todo : look 72 line
 */

import React from 'react';
import DefaultContainer from 'components/DefaultContainer';
import Loader from 'components/atoms/Loader';
import Error from 'components/molecules/Error';
import ExamineDetail from './detail';
import ENV from 'config/config';

const ExaminePresenter = ({ branchList, isLoading, isError }) => {
  return (
    <DefaultContainer>
      {/* 에러나는 경우 필터 + 목록 대신 에러메시지 출력 */}
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Error
          text="데이터를 불러오는데 실패하였습니다."
          color="#1dc1bb"
          bgUrl={`${ENV.CDN_IMAGE_PATH}/icon/myzzym_nonexist.png`}
        />
      ) : branchList.length === 0 ? (
        <Error
          text="조건에 일치하는 창고 목록이 존재하지 않습니다."
          color="#1dc1bb"
          bgUrl={`${ENV.CDN_IMAGE_PATH}/icon/myzzym_nonexist.png`}
        />
      ) : (
        <ExamineDetail branchList={branchList} />
      )}
    </DefaultContainer>
  );
};

export default ExaminePresenter;
