import styled from 'styled-components';
import * as styles from 'util/style/Styles';

export const OuterContainer = styled.div`
  padding: 30px;
  position: relative;
  display: inline-block;
  width: ${(props) => (props.width != null ? `${props.width}` : '100%')};
  overflow: hidden;
`;

export const Title = styled.h1`
  font-size: 25px;
`;

export const SubTitle = styled.div`
  margin-top: 24px;
  font-size: 20px;
`;

export const DeleteBtn = styled.button`
  float: right;
  color: black;
  background-color: black;
  margin-right: 10px;
  color: white;
`;

export const Wrapper = styled.div`
  margin-top: 25px;
`;

///////////////////
// Contact List  //
///////////////////

export const ContactList = styled.ul`
  padding: ${styles.Padding};
`;

export const ContactContent = styled.li`
  border-bottom: solid 1px #d3d3d3;
  padding-bottom: 10px;
`;

export const CheckBox = styled.input`
  float: left;
  margin-top: 54px;
  margin-right: 20px;
`;

export const ContentWrapper = styled.div`
  display: inline-block;
`;

export const NameWrapper = styled.div`
  font-size: 20px;
  padding: 20px;
  font-weight: 600;
`;

export const ContactText = styled.div`
  color: gray;
  font-size: 15px;
  font-weight: 300;
  padding: 6px 20px;
`;
