import React from 'react';
import DeleteFormPresenter from './DeleteFormPresenter';

const DeleteFormContainer = ({ changeShowModal, item, formTitle }) => {
  // 이벤트 서버에서 이벤트(알림에서의 이벤트 의미하는 것 아님) 삭제
  const deleteNotification = async () => {
    alert('현재 지원하지 않는 기능입니다.');
  };
  return (
    <DeleteFormPresenter
      changeShowModal={changeShowModal}
      deleteNotification={deleteNotification}
      formTitle={formTitle}
    />
  );
};

export default DeleteFormContainer;
