import React, { useState, useEffect } from 'react';
import PersonalDetailPresenter from './PersonalDetailPresenter';
import * as format from 'util/fomatter/Formatter';

const PersonalDetailContainer = ({ items, pathname }) => {
  const [index, setIndex] = useState(0);
  const [typeIdx, setTypeIdx] = useState(0);
  const [currentItem, setCurrentItem] = useState(items[0]);
  const [currentList, setCurrentList] = useState(items);

  // typeIdx 변경시 리렌더링
  useEffect(() => {
    setCurrentItem(currentList[0]);
  }, [typeIdx]);

  const onChangeItem = (idx) => {
    setCurrentItem(currentList[idx]);
    setIndex(idx);
  };
  const filterItem = (e, idx) => {
    let tempList = [];
    if (idx === 1) {
      items.map((item, index) => {
        // 푸시미설정
        if (format.isInitialDate(item.pushDate)) {
          tempList.push(item);
        }
      });
      setTypeIdx(1);
    } else if (idx === 2) {
      items.map((item, index) => {
        // 푸시설정
        if (!format.isInitialDate(item.pushDate)) {
          tempList.push(item);
        }
      });
      setTypeIdx(2);
    } else {
      tempList = items;
      setTypeIdx(0);
    }
    setCurrentList(tempList);
  };
  return (
    <PersonalDetailPresenter
      items={currentList}
      currentItem={currentItem}
      onChangeItem={onChangeItem}
      filterItem={filterItem}
      index={index}
      typeIdx={typeIdx}
      pathname={pathname}
    />
  );
};

export default PersonalDetailContainer;
