import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'views/Auth/PrivateRoute';
import Header from 'components/organisms/header/HeaderContainer';
import SubHeader from 'components/organisms/subheader/SubHeader';

// 로그인
import Login from 'views/Auth/Login';
// 마이짐 - 검수
import Examine from 'views/myzzym/Examine';
// 마이직 - 예약
import Booking from 'views/myzzym/Booking';
// 마이짐 - 결산
import Earning from 'views/myzzym/Earning';
// 마이짐 - 문의
import Contacts from 'views/myzzym/Contacts';
// 마이짐 - 알림>공지사항
import Notice from 'views/myzzym/Notification/Notice';
// 마이짐 - 알림>이벤트
import Event from 'views/myzzym/Notification/Event';
// 마이짐 - 알림>퍼스널
import Personal from 'views/myzzym/Notification/Personal';
// 마이클래스 - 문의 내역
import Contact from 'views/maiclass/Contact';
import { AuthContext } from 'context/auth';

import { userApi } from 'api/api-user';
import { api } from 'api/api-base';
import promiseHandler from 'util/promiseHandler';

/*
 * path 는 URL 이다.
 * 사용자가 이벤트 발생 시 이에 해당하는 컴포넌트로 이동한다.
 * Router components props: component vs render
 *
 * PrivateRoute 부분은 추가 수정해야함. by kimpro 2021.01.27
 *
 */

function Routers(props) {
  //1. 기존에 browser local stroage에 token이 존재하는지 확인한다.
  const [authTokens, setAuthTokens] = useState();
  const [isFinishAuth, setIsFinishAuth] = useState(false);

  const logout = async () => {
    const [logout, logoutErr] = await promiseHandler(userApi.logoutUser());
    if (logoutErr) {
      alert('문제가 발생하였습니다. 다시 시도해주세요');
      return;
    }
    delete api.defaults.headers.common[`Authorization`];
    setAuthTokens();
  };

  /**
   * 1. refreshToken으로 refreshUser() - accessToken 재발급받기
   * 2. authTokens accessToken 설정
   *  2-1. api header Authorization 설정
   *  2-2 setAuthTokens
   */
  const checkLogin = async () => {
    const [refresh, refreshErr] = await promiseHandler(userApi.refreshUser());

    if (refreshErr) {
      let errorResponse = refreshErr.response;
      /**
       * api 호출 에러 - 에러 코드로 refreshToken 여부 구분
       * 현재 두가지로 구분됨
       * 1. refreshToken이 없는경우 -> 아무처리X
       * 2. refreshToken이 만료된경우 -> 로그아웃 처리 되어야함 (refreshToken을 지우기 위함)
       */
      if (errorResponse) {
        let code = errorResponse.data.code;
        // refreshToken 만료된 경우 -> 로그아웃 - refreshToken 삭제
        if (code === 'I007') {
          logout();
        }
      } else {
        alert('문제가 발생하였습니다. 다시 시도해주세요');
      }
      return;
    }

    const { data } = refresh.data;
    const { accessToken } = data;
    api.defaults.headers.common[`Authorization`] = `Bearer ${accessToken}`;
    setAuthTokens({ accessToken: accessToken });

    setIsFinishAuth(true);
  };

  /**
   * 처음 페이지 접근 시 refreshToken 관련 체크 후 accessToken을 발급받아 로그인 유지 확인
   */
  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authTokens: authTokens,
        isFinishAuth: isFinishAuth,
        setAuthTokens: setAuthTokens,
        setIsFinishAuth: setIsFinishAuth,
      }}
    >
      <BrowserRouter>
        {/* 메뉴바 - 로그인 페이지 시 X */}
        {/* 메인 */}
        <>
          <Header />
          <SubHeader />
        </>

        <>
          {/* 디폴트 라우터는 모든 라우터들에게 props를 준다!!!! */}
          <Switch>
            <PrivateRoute exact path="/" component={Examine} />

            {/* myzzym 검수 --------------------------------------------*/}
            <PrivateRoute path="/myzzym/examine" component={Examine} />

            {/* myzzym 검수 --------------------------------------------*/}
            <PrivateRoute path="/myzzym/booking" component={Booking} />

            {/* myzzym 결산 --------------------------------------------*/}
            <PrivateRoute path="/myzzym/earning" component={Earning} />

            {/* myzzym 문의 --------------------------------------------*/}
            <PrivateRoute path="/myzzym/contacts" component={Contacts} />

            {/* myzzym 공지사항 -----------------------------------------*/}
            <PrivateRoute path="/myzzym/notice" component={Notice} />

            {/* myzzym 이벤트 ------------------------------------------*/}
            <PrivateRoute path="/myzzym/event" component={Event} />

            {/* myzzym 퍼스널 ------------------------------------------*/}
            <PrivateRoute path="/myzzym/personal" component={Personal} />

            {/* maiclass 검수 --------------------------------------------*/}
            <PrivateRoute path="/maiclass/examine" component={Examine} />

            {/* maiclass 결산 --------------------------------------------*/}
            <PrivateRoute path="/maiclass/earning" component={Earning} />

            {/* 문의 내역 ----------------------------------------*/}
            <PrivateRoute path="/maiclass/contact" exact component={Contact} />

            {/* 내땅 검수 --------------------------------------------*/}
            <PrivateRoute path="/land/examine" component={Examine} />

            {/* 내땅 결산 --------------------------------------------*/}
            <PrivateRoute path="/land/earning" component={Earning} />
            {/* 로그인 페이지 */}
            <Route path="/login" render={(props) => <Login {...props} />} />
            {/* 사용자가 잘못된 경로로 접근 시 메인페이지로 리다이렉트한다. */}
            <Redirect from="*" to="/" />
          </Switch>
        </>
        {/* 푸터 */}
        {/* <Footer /> */}
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default Routers;
