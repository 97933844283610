import React from 'react';
import DefaultContainer from 'components/DefaultContainer';
import BookingDetail from './detail';
import BookingSearch from './search';

const BookingPresenter = ({ bookingList, setSearchParams }) => {
  return (
    <DefaultContainer>
      <BookingSearch setSearchParams={setSearchParams} />
      <BookingDetail bookingList={bookingList} />
    </DefaultContainer>
  );
};

export default BookingPresenter;
