import React, { useState, useCallback } from 'react';
import ReserveFormPresenter from './ReserveFormPresenter';
import moment from 'moment';

const ReserveFormContainer = ({ changeShowModal, item }) => {
  console.log('[푸시알림 예약 시작]');

  const [pushDate, setPushDate] = useState(moment());
  const [pushTime, setPushTime] = useState(moment());

  const onChange = useCallback((e) => {
    const { name, value } = e.target;
    if (name === 'pushDate') {
      const now = moment();
      const targetDate = moment(value);
      if (targetDate.diff(now) < 0) {
        alert('현재 날짜의 1일 이후부터 예약 가능합니다.');
      } else {
        setPushDate(targetDate);
      }
    } else if (name === 'pushTime') {
      setPushTime(value);
    }
  }, []);

  // 푸시알림 전송 일시 예약하기
  const reserveEvent = async () => {
    alert('현재 지원하지 않는 기능입니다.');
  };
  return (
    <ReserveFormPresenter
      changeShowModal={changeShowModal}
      pushDate={pushDate}
      pushTime={pushTime}
      onChange={onChange}
      reserveEvent={reserveEvent}
    />
  );
};

export default ReserveFormContainer;
