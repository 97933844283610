import React from 'react';
import { payApi } from 'api/api-pay';
import BookingContentPresenter from './BookingContentPresenter';
import { getStatusString } from '../BookingDetailContainer';

const cancelBookingOf = async (item) => {
  const bookingStatus = getStatusString(item.status);
  if (bookingStatus !== '결제완료') {
    alert(`결제완료 상태가 아닙니다.`);
    return;
  }

  const payCancelParams = {
    moid: item.id,
    cancelAmount: item.amount,
    msg: '관리자취소',
  };

  try {
    await payApi.payCancel(payCancelParams);
    alert(`예약(${payCancelParams.moid})을 취소하였습니다.`);
  } catch (error) {
    console.log('Error: ', error);
    alert(`[${error.code}] ${error.response.data.message}`);
  }
};

const BookingContentConainer = ({ item }) => {
  const cancelBooking = () => cancelBookingOf(item);

  return <BookingContentPresenter item={item} cancelBooking={cancelBooking} />;
};

export default BookingContentConainer;
