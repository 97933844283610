import React, { useState } from 'react';
import * as s from '../ExamineContentStyled';
import * as format from 'util/fomatter/Formatter';
import ENV from 'config/config';
import Modal from 'components/molecules/modal/Modal';

const BranchPresenter = ({ item, handleCheckbox }) => {
  // 이미지 자세히 보기 모달
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const changeShowModal = () => setShowModal(!showModal);
  return (
    <s.BranchContainer>
      {/* 창고 종류 */}
      <s.BranchItem>
        <s.BranchItemTitle>창고 종류</s.BranchItemTitle>
        <s.BranchItemLabel>
          <s.BranchItemDesc>
            {item.storageType === 'p'
              ? '개인창고'
              : item.storageType === 's'
              ? '셀프 스토리지'
              : '물류창고'}
          </s.BranchItemDesc>
          <s.BranchInput
            type="checkbox"
            name="storageType"
            onClick={handleCheckbox}
          />
        </s.BranchItemLabel>
      </s.BranchItem>

      {/* 창고명 */}
      <s.BranchItem>
        <s.BranchItemTitle>창고명</s.BranchItemTitle>
        <s.BranchItemLabel>
          <s.BranchItemDesc>{item.branchName}</s.BranchItemDesc>
          <s.BranchInput
            type="checkbox"
            name="branchName"
            onClick={handleCheckbox}
          />
        </s.BranchItemLabel>
      </s.BranchItem>

      {/* 소개 */}
      <s.BranchItem>
        <s.BranchItemTitle>소개</s.BranchItemTitle>
        <s.BranchItemLabel>
          <s.BranchItemDesc>{item.branchDescription}</s.BranchItemDesc>
          <s.BranchInput
            type="checkbox"
            name="branchDescription"
            onClick={handleCheckbox}
          />
        </s.BranchItemLabel>
      </s.BranchItem>

      {/* 주소 */}
      <s.BranchItem>
        <s.BranchItemTitle>주소</s.BranchItemTitle>
        <s.BranchItemLabel>
          <s.BranchItemDesc>
            {`${item.zipcode}, ${item.roadAddress} ${item.detailAddress}`}
            지번 {item.streetAddress}
          </s.BranchItemDesc>
          <s.BranchInput
            type="checkbox"
            name="roadAddress"
            onClick={handleCheckbox}
          />
        </s.BranchItemLabel>
      </s.BranchItem>

      {/* 전화번호 */}
      <s.BranchItem>
        <s.BranchItemTitle>전화번호</s.BranchItemTitle>
        <s.BranchItemLabel>
          <s.BranchItemDesc>
            {format.telNumberFormatter(item.branchPhone)}
          </s.BranchItemDesc>
          <s.BranchInput
            type="checkbox"
            name="branchPhone"
            onClick={handleCheckbox}
          />
        </s.BranchItemLabel>
      </s.BranchItem>

      {/* 운영시간 */}
      <s.BranchItem>
        <s.BranchItemTitle>운영시간</s.BranchItemTitle>
        <s.BranchItemLabel>
          <s.BranchItemDesc>
            {item.branchStartTime} ~ {item.branchEndTime}
          </s.BranchItemDesc>
          <s.BranchInput
            type="checkbox"
            name="branchTime"
            onClick={handleCheckbox}
          />
        </s.BranchItemLabel>
      </s.BranchItem>

      {/* 홈페이지 */}
      <s.BranchItem>
        <s.BranchItemTitle>홈페이지</s.BranchItemTitle>
        <s.BranchItemLabel>
          <s.BranchItemDesc>
            <a
              href={item.branchWeb ? item.branchWeb : null}
              rel="noreferrer"
              target="_blank"
            >
              {item.branchWeb ? item.branchWeb : '없음'}
            </a>
          </s.BranchItemDesc>
          <s.BranchInput
            type="checkbox"
            name="branchWeb"
            onClick={handleCheckbox}
          />
        </s.BranchItemLabel>
      </s.BranchItem>

      {/* 창고사진 */}
      <s.BranchItem>
        <s.BranchItemTitle>창고사진</s.BranchItemTitle>
        <s.BranchItemLabel>
          <s.BranchItemDesc>
            {item.branchImages.length > 0 ? (
              <s.ImgGroup>
                <s.ImgContainer>
                  {item.branchImages.map((branch, index) => (
                    <s.ImgWrapper key={index}>
                      <s.Img
                        src={`${ENV.CDN_IMAGE_PATH}/branch/${item.id}/${branch.fileName}`}
                        onClick={() => {
                          setSelectedIndex(index);
                          changeShowModal();
                        }}
                      />
                    </s.ImgWrapper>
                  ))}
                  {showModal && (
                    <Modal
                      image
                      show={true}
                      changeShow={changeShowModal}
                      header={'이미지 자세히 보기'}
                      onClickFooterButton={changeShowModal}
                      footerButtonText={'닫기'}
                    >
                      <s.Img
                        alt=""
                        src={`${ENV.CDN_IMAGE_PATH}/branch/${item.id}/${item.branchImages[selectedIndex].fileName}`}
                      />
                    </Modal>
                  )}
                </s.ImgContainer>
              </s.ImgGroup>
            ) : null}
          </s.BranchItemDesc>
          <s.BranchInput
            type="checkbox"
            name="branchImage"
            onClick={handleCheckbox}
          />
        </s.BranchItemLabel>
      </s.BranchItem>
    </s.BranchContainer>
  );
};

export default BranchPresenter;
