import React, { useState, useEffect } from 'react';
import CompletePresenter from './CompletePresenter';
import moment from 'moment';
import earningRecordApi from 'api/api-earningRecord';

/**
 * 지급완료 EarningRecord 조회
 */
const CompleteContainer = ({ curUserIndex, user }) => {
  const [earningRecords, setEarningRecords] = useState([]);

  /**
   * 날짜 필터
   */
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChangeStartDate = (date) => {
    setStartDate(date);
  };
  const onChangeEndDate = (date) => {
    setEndDate(date);
  };

  // EarningRecode 조회
  const fetchData = async () => {
    let params = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      paymentsStatus: 'INSERTCOMPLETE',
    };

    let result = null;
    try {
      ({ data: result } = await earningRecordApi.searchEarningRecordsByUser(
        user.id,
        params,
      ));
      setEarningRecords(result);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * User 변경 시 초기화
   */
  useEffect(() => {
    setEarningRecords([]);
    setStartDate(new Date());
    setEndDate(new Date());
  }, [curUserIndex]);

  return (
    <>
      <CompletePresenter
        earningRecords={earningRecords}
        startDate={startDate}
        endDate={endDate}
        onChangeStartDate={onChangeStartDate}
        onChangeEndDate={onChangeEndDate}
        fetchData={fetchData}
      ></CompletePresenter>
    </>
  );
};

export default CompleteContainer;
