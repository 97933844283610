import React, { useState, useEffect } from 'react';
import { contactApi } from 'api/api-contact';
import ContactPresenter from './ContactPresenter';

const ContactContainer = (props) => {
  const [contactList, setContactList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // 삭제 버튼 눌렀을 때 chekedList의 값들 모두 삭제
  const onDeleteIds = async () => {
    try {
      if (checkedList.length === 0) {
        throw new Error('적어도 하나의 문의 내역을 선택해야합니다');
      }
      if (
        window.confirm(
          `총 ${checkedList.length}건의 문의 내역을 삭제하시겠습니까?`,
        )
      ) {
        await contactApi.deleteContacts(checkedList);
        alert(`총 ${checkedList.length}건의 문의 내역이 삭제되었습니다.`);
        //본 컴포넌트의 리랜더링을 위한 contactList length 변화
        contactList.splice(0, 1);
        setCheckedList([]);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  // 체크 박스 눌러서 삭제할 리스트에 추가
  const onClickCheckBox = (id) => {
    const indexOfId = checkedList.indexOf(id);

    //있으면 제거
    if (indexOfId >= 0) {
      checkedList.splice(indexOfId, 1);
      setCheckedList(checkedList);
    }
    //없으면 추가
    else {
      checkedList.push(id);
      setCheckedList(checkedList);
    }
  };

  useEffect(() => {
    async function fetchData() {
      let result = null;
      try {
        result = await contactApi.getContactList();
      } catch (error) {
        //console.log(error);
      } finally {
        if (result) {
          setContactList(result.data.list);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setIsError(true);
        }
      }
    }
    fetchData();
  }, [contactList.length]);

  return (
    <ContactPresenter
      contactList={contactList}
      checkedList={checkedList}
      isLoading={isLoading}
      isError={isError}
      onDeleteIds={onDeleteIds}
      onClickCheckBox={onClickCheckBox}
    ></ContactPresenter>
  );
};

export default ContactContainer;
