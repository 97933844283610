import React, { useState } from 'react';
import ExamineDetailPresenter from './ExamineDetailPresenter';

const ExamineDetailContainer = ({ branchList }) => {
  const [index, setIndex] = useState(0);
  const [typeIdx, setTypeIdx] = useState(0);
  const [currentItem, setCurrentItem] = useState(branchList[0]);
  const [currentList, setCurrentList] = useState(branchList);

  const onChangeItem = (idx) => {
    setCurrentItem(currentList[idx]);
    setIndex(idx);
  };

  const filterItem = (e, idx) => {
    let tempList = [];

    if (idx === 1) {
      branchList.map((branch, index) => {
        // console.log(branch, ", branch");
        if (branch.isExamine === 0) {
          tempList.push(branch);
        }
      });
      setTypeIdx(1);
    } else if (idx === 2) {
      branchList.map((branch, index) => {
        if (branch.isExamine === 2) {
          tempList.push(branch);
        }
      });
      setTypeIdx(2);
    } else {
      tempList = branchList;
      setTypeIdx(0);
    }
    setCurrentList(tempList);
  };

  return (
    <ExamineDetailPresenter
      branchList={currentList}
      currentItem={currentItem}
      onChangeItem={onChangeItem}
      filterItem={filterItem}
      index={index}
      typeIdx={typeIdx}
    />
  );
};

export default ExamineDetailContainer;
