import styled from 'styled-components';
import { MyzzymTheme } from 'util/style/Styles';
import { BsXCircle } from 'react-icons/bs';

// 모달 내부 영역
export const Modal = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const ModalSection = styled.section`
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 10%;
  border-radius: 0.3rem;
  background-color: ${MyzzymTheme.White};
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
`;

export const ModalHeader = styled.header`
  position: relative;
  padding: 16px 64px 16px 16px;
  background-color: ${MyzzymTheme.LightGray};
  font-weight: 700;
`;

export const ModalHeaderButton = styled(BsXCircle)`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
  &:hover {
    color: ${MyzzymTheme.Black};
  }
`;

export const ModalBody = styled.main`
  padding: ${(props) => (props.showImg ? null : '16px')};
  object-fit: ${(props) => (props.showImg ? 'fill' : null)};
  border-bottom: 1px solid ${MyzzymTheme.GrayBorder};
  border-top: 1px solid ${MyzzymTheme.GrayBorder};
`;

export const ModalFooter = styled.footer`
  padding: 12px 16px;
  text-align: right;
`;

export const ModalFooterButton = styled.button`
  padding: 6px 12px;
  color: ${MyzzymTheme.White};
  background-color: ${MyzzymTheme.MyzzymColor};
  border-radius: 5px;
  font-size: 0.813rem;
  &:hover {
    background-color: ${MyzzymTheme.MyzzymFormColor};
  }
`;
