import React, { useState, useEffect } from 'react';
import NoticePresenter from './NoticePresenter';
import moment from 'moment';

const NoticeContainer = ({ location: { pathname } }) => {
  const [noticeList, setNoticeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchData = async () => {
    // notification 서버에서 이벤트 조회했었지만 notification 서버 제거로 인해 현재 불러오는 데이터 없음
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <NoticePresenter
      noticeList={noticeList}
      isLoading={isLoading}
      isError={isError}
      pathname={pathname}
    />
  );
};

export default NoticeContainer;
