import React, { useEffect, useState } from 'react';
import BookingSideListPresenter from './BookingSideListPresenter';
import {
  AllStatusString,
  getStatusString,
} from 'views/myzzym/Booking/detail/BookingDetailContainer';

const SideListContainer = ({ category, filterList, items, setCurrentItem }) => {
  const [index, setIndex] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(filterList[0]);
  const [currentList, setCurrentList] = useState(items);

  const onChangeItem = (idx) => {
    setCurrentItem(currentList[idx]);
    setIndex(idx);
  };

  const filterItems = (filterName) => {
    setSelectedFilter(filterName);
    if (filterName === AllStatusString) {
      setCurrentList(items);
      return;
    }

    const tempList = items.filter(
      (booking) => getStatusString(booking.status) === filterName,
    );
    setCurrentList(tempList);
  };

  useEffect(() => {
    filterItems(AllStatusString);
  }, [items]);

  return (
    <BookingSideListPresenter
      category={category}
      list={filterList}
      filterList={filterList}
      onChangeItem={onChangeItem}
      filterItems={filterItems}
      items={currentList}
      selectedFilter={selectedFilter}
      index={index}
    />
  );
};

export default SideListContainer;
