import React from 'react';
import Router from 'components/Router';
import GlobalStyles from 'components/GlobalStyles';
import sentry from './sentry';

const App = () => {
  // for error monitoring
  sentry.initiate();

  return (
    <>
      <Router />
      <GlobalStyles />
    </>
  );
};

export default App;
