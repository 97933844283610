/**
 * @author suyeon-jung
 * @date 2021.07.26
 * @description 이벤트 및 공지사항 삭제를 위한 폼
 */
import React from 'react';
import Modal from 'components/molecules/modal/Modal';

const DeleteFormPresenter = ({
  changeShowModal,
  deleteNotification,
  formTitle,
}) => {
  return (
    <Modal
      show={true}
      changeShow={changeShowModal}
      header={`${formTitle} 삭제`}
      onClickFooterButton={deleteNotification}
      footerButtonText={'네, 삭제하겠습니다.'}
    >
      정말로 삭제하시겠습니까?
    </Modal>
  );
};

export default DeleteFormPresenter;
