import React, { useState, useEffect } from 'react';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import locationApi from 'api/api-location';
import { branchApi } from 'api/api-branch';
import { useAuth } from 'context/auth';
import { checkListFormatter } from 'util/fomatter/Formatter';
import ExamineContentPresenter from './ExamineContentPresenter';
import ENV from 'config/config';
import { CATEGORY, TYPE } from 'common';

const ExamineContentContainer = ({ item }) => {
  const { authTokens } = useAuth();
  const decodedAccessToken =
    authTokens && authTokens.accessToken
      ? jwtDecode(authTokens.accessToken)
      : null;
  // 위도
  const [latitude, setLatitude] = useState(null);
  // 경도
  const [longitude, setLongitude] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const [extra, setExtra] = useState(null);

  const handleExtra = (e) => {
    const { value } = e.target;
    setExtra(value);
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setCheckedList([...checkedList, name]);
    } else {
      setCheckedList(checkedList.filter((x) => x !== name));
    }
  };

  const getLatLng = async (roadAddress) => {
    let result = null;
    try {
      let params = {
        query: roadAddress,
      };
      console.log('-----------roadAddress, ', roadAddress);
      result = await locationApi.searchInfo(params);

      console.log('-----------result, ', result);
    } catch (error) {
      console.log(error);
    } finally {
      if (
        result.data &&
        result.status === 200 &&
        result.data.documents.length > 0
      ) {
        setLongitude(result.data.documents[0].x);
        setLatitude(result.data.documents[0].y);
      } else {
        alert('주소의 좌표를 받아오지 못했습니다!');
      }
    }
  };

  /*
   * 검수 완료
   */
  const complete = async (branch) => {
    const { user, id, branchName, branchImages } = branch;
    const branchImage = `${ENV.CDN_IMAGE_PATH}/branch/${id}/${branchImages[0]?.fileName}`;
    let updateResult = null;
    let examineResult = null;
    try {
      let branchInfo = {
        latitude: latitude,
        longitude: longitude,
      };
      updateResult = await branchApi.updateBranch(id, branchInfo);
      // 상태값 업데이트 완료 후 알림톡 보내기
      examineResult = await branchApi.updateBranchExamineComplete(id);
    } catch (error) {
      console.log('위도 경도 업데이트 에러', error);
    } finally {
      if (
        updateResult &&
        updateResult.status === 200 &&
        examineResult &&
        examineResult.status === 200
      ) {
        alert('검수 승인이 완료되었습니다.');
        window.location.reload();
      }
    }
  };

  /**
   * 검수 반려 절차
   * 1. isExamine 상태값 변경(0->2) -> 상태값 업데이트가 성공적이라면 알림톡, 푸시알림 전송
   * 2. 반려 알림톡, 푸시알림 보내기
   */
  const reject = async (branch) => {
    const { user, id, branchName, branchImages } = branch;
    const branchImage = `${ENV.CDN_IMAGE_PATH}/branch/${id}/${branchImages[0]?.fileName}`;
    let result = null;
    // 반려항목
    const itemsToReject = checkListFormatter(checkedList);
    // 참고사항
    const commentToReject = extra ? extra : '';

    try {
      //DB 업데이트 및 알림톡 전송
      result = await branchApi.updateBranchExamineReject(
        id,
        itemsToReject,
        commentToReject,
      );
    } catch (error) {
      console.log('반려 에러', error);
    } finally {
      if (result && result.status === 200) {
        alert('검수 반려가 완료되었습니다.');
        window.location.reload();
      }
    }
  };

  /**
   * 검수 승인 절차
   * 1. 위도, 경도 조회
   * 2. 서버에 위도, 경도 업데이트
   * 3. isExamine 상태값 변경(0->1)
   * 4. 승인 알림톡, 푸시알림 보내기
   */
  useEffect(() => {
    console.log(1111);
    if (latitude && longitude) {
      console.log('위도 경도 업데이트!', latitude, longitude);
      complete(item);
    }
  }, [latitude, longitude]);
  return (
    <ExamineContentPresenter
      item={item}
      checkedList={checkedList}
      handleCheckbox={handleCheckbox}
      handleExtra={handleExtra}
      reject={reject}
      getLatLng={getLatLng}
    />
  );
};

export default ExamineContentContainer;
