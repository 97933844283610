import React from 'react';
import * as s from '../request/RequestStyled';
import { priceToFormat } from 'util/fomatter/Formatter';
import moment from 'moment';

import Button from 'components/atoms/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CompletePresenter = ({
  earningRecords,
  startDate,
  endDate,

  onChangeStartDate,
  onChangeEndDate,

  fetchData,
}) => {
  return (
    <>
      <s.TitleContainer>
        <s.HighLight>지급완료 내역 확인 (지급일 기준 날짜 필터링)</s.HighLight>
      </s.TitleContainer>

      <s.FilterContainer>
        {/* 시작날짜 선택 */}
        <s.StartDateFilterDiv>
          <DatePicker
            selected={startDate}
            onChange={onChangeStartDate}
            dateFormat={'yyyy-MM-dd'}
          ></DatePicker>
          (지급일 기준 조회 시작일)
        </s.StartDateFilterDiv>

        {/* 끝날짜 선택 */}
        <s.EndDateFilterDiv>
          <DatePicker
            selected={endDate}
            onChange={onChangeEndDate}
            dateFormat={'yyyy-MM-dd'}
          ></DatePicker>
          (지급일 기준 조회 종료일)
        </s.EndDateFilterDiv>

        {/* 조회 버튼 */}
        <Button text={'조회'} onClick={fetchData}></Button>
      </s.FilterContainer>

      <s.EarningRecordContainer>
        <s.ItemContainer>
          {/* EarningRecord.id  */}
          <s.ItemId>결산기록(EarningRecord) No.</s.ItemId>
          {/* 결산기간 */}
          <s.ItemEarningRange>결산기간</s.ItemEarningRange>
          {/* 지급일자 - EarningRecord.dtPay */}
          <s.ItemDtPay>지급일</s.ItemDtPay>
          {/* 지급액 - EarningRecord.amtSupply */}
          <s.ItemAmtSupply>지급액</s.ItemAmtSupply>
        </s.ItemContainer>
        {earningRecords.map((earningRecord, index) => (
          <s.ItemContainer key={index}>
            {/* EarningRecord.id  */}
            <s.ItemId>{earningRecord.id}</s.ItemId>
            {/* 결산기간 */}
            <s.ItemEarningRange>
              {earningRecord.earningStartDate} ~ {earningRecord.earningEndDate}
            </s.ItemEarningRange>
            {/* 지급일자 - EarningRecord.dtPay */}
            <s.ItemDtPay>
              {moment(earningRecord.dtPay, 'YYYYMMDD').format('YYYY-MM-DD')}
            </s.ItemDtPay>
            {/* 지급액 - EarningRecord.amtSupply */}
            <s.ItemAmtSupply>
              {priceToFormat(parseInt(earningRecord.amtSupply))}
            </s.ItemAmtSupply>
          </s.ItemContainer>
        ))}
      </s.EarningRecordContainer>
    </>
  );
};

export default CompletePresenter;
