/**
 *
 * @author ydoni
 * @date 2021.07.20
 * @description side list
 */

import React from 'react';
import * as s from '../SideListStyled';

const Type = ({ list, filterItem, typeIdx }) => {
  return (
    <s.TypeContainer>
      {list &&
        list.map((item, idx) => (
          <s.TypeItem
            typeIdx={typeIdx}
            idx={idx}
            key={idx}
            onClick={(e) => filterItem(e, idx)}
          >
            {item.name}
          </s.TypeItem>
        ))}
    </s.TypeContainer>
  );
};

export default Type;
