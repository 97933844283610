import styled from 'styled-components';
import * as styles from 'util/style/Styles';

export const MyzzymTheme = {
  MyzzymColor: '#1bc1bb',
  MyzzymNoTrans: '#f2fbfc',
  MyzzymFormColor: '#00848A',
  MyzzymOpacity: 'rgba(27,193,187,0.03)',
  LightMyzzymColor: 'rgba(27,193,187,0.05)',
  LightGray: '#fafafa',
  Gray: '#8d8d8d',
  GrayBorder: '#ededed',
  DeepGray: '#757575',
  Black: '#303030',
  PlaceHolderColor: '#AFAFAF',
  Blue: 'rgb(36, 167, 255)',
  White: '#FFFFFF',
  Green: '#49C219',
  Red: '#ff4949',
  Orange: '#FDAC2D',
};

// earning title
export const TitleContainer = styled.div`
  font-size: 1.125rem;
  padding: ${styles.Padding};
  ${styles.FlexBetween};
`;
export const Title = styled.div`
  flex: 10;
`;

export const HighLight = styled.strong`
  font-weight: 600;
`;

// earning 정보
export const EarningItem = styled.li`
  ${styles.FlexStart};
  padding: ${styles.Padding};
`;

export const EarningItemTitle = styled.span`
  flex: 1;
  color: ${styles.MyzzymTheme.Gray};
`;

export const EarningItemDesc = styled.span`
  flex: 6;
`;

/**
 * @author dong
 * @date 2021.07.30
 * @description 기존 CSS를 건드리지 않고 사용만하고 새로 추가한 CSS들은 아래로 추가 -> 추후 최주임 수정 시 삭제해도 상관없음
 */
export const EarningItemContainer = styled.div`
  ${styles.FlexBetween}
  /* background-color: gray; */
  margin: 10px;
  padding: 10px;
  border: solid;
  border-radius: 1px;
  border-width: 3px;
  border-color: black;
`;

export const EarningItemCheck = styled.div`
  flex: 1;
`;
export const EarningItemCheckButton = styled.bu;

export const EarningModifiedDate = styled.div`
  flex: 3;
`;

export const EarningAmount = styled.div`
  flex: 3;
`;

export const EarningStatus = styled.div`
  flex: 3;
`;

export const EarningDtPayDiv = styled.div`
  width: 70%;
`;

export const EarningDtPayInput = styled.input`
  background-color: ${MyzzymTheme.LightGray};
  width: 100%;
`;

export const EarningUpdateContainer = styled.div`
  ${styles.FlexBetween};
  padding: 10px;
`;

export const EarningUpdateButton = styled.button``;

export const EarningInfoKey = styled.div`
  flex: 2;
  text-align: center;
`;

export const EarningInfoValue = styled.div`
  flex: 5;
  /* ${styles.FlexBetween} */
`;

export const EarningInfoValueInput = styled.input`
  background-color: ${MyzzymTheme.LightGray};
  width: 100%;
`;
