import { api } from './api-base';

const bookingApi = {
  /**
   * @GetMapping("/branches/{branchId}/units/bookings")
   */
  searchBookingsByBranch: (branchId, params) =>
    api.get(`/branches/${branchId}/units/bookings`, {
      params: params,
    }),
  searchBookingsByBookingId: (bookingId) => api.get(`/bookings/${bookingId}`),
  searchBookingsByUserId: (userId) => api.get(`/users/${userId}/bookings`),
  searchBookingsByBranchId: (branchId) =>
    api.get(`/branches/${branchId}/units/bookings`),
};

export default bookingApi;
