import React, { useEffect, useState } from 'react';
import BookingPresenter from './BookingPresenter';
import { bookingSearchOptions } from './search/BookingSearchOptions';

const initialSearchParams = { key: null, value: null };

const ensureArray = (input) => {
  if (!Array.isArray(input)) {
    return [input];
  }
  return input;
};

const BookingContainer = () => {
  const [bookingList, setBookingList] = useState([]);
  const [searchParams, setSearchParams] = useState(initialSearchParams);

  const { key: searchKey, value: searchValue } = searchParams;

  const bookingSearchApi = bookingSearchOptions.filter(
    ({ key }) => key === searchKey,
  )[0]?.api;

  const fetchBookingData = async () => {
    if (!searchValue) return;
    if (!bookingSearchApi) return;
    try {
      const { data } = await bookingSearchApi(searchValue);
      const searchResult = ensureArray(data);
      setBookingList(searchResult);
    } catch (error) {
      console.log('Error: ', error);
      alert(`[${error.code}] ${error.response.data.message}`);
    }
  };

  useEffect(() => {
    fetchBookingData();
  }, [searchKey, searchValue]);

  return (
    <BookingPresenter
      bookingList={bookingList}
      setSearchParams={setSearchParams}
    />
  );
};

export default BookingContainer;
