/*********************************
//*** @Author : wonseok ***
//*** @Date : 2020.09.22 ***
//*** @Title : make admin login ***
//*** @todo : 관리자만 로그인되게 ( 현재 마이짐 유저 테이블 사용 ) ***
*********************************/

/**
 * @author suyeon-jung
 * @date 2021.06.21
 * @description 로그인 로직 수정
 */

import React, { useState } from 'react';
import * as s from './LoginStyled';
import DefaultContainer from 'components/DefaultContainer';
import { useAuth } from 'context/auth';
import { userApi } from 'api/api-user';
import { api } from 'api/api-base';
import LoginResultCode from 'util/constants/LoginResultCode';
import promiseHandler from 'util/promiseHandler';

const Login = ({ history }) => {
  const [isError, setIsError] = useState(false);
  const [userEmail, setUserName] = useState('');
  const [userPassword, setPassword] = useState('');

  const { setAuthTokens } = useAuth();

  // 로그인
  const Login = async () => {
    let formData = new FormData();
    formData.append('userEmail', userEmail);
    formData.append('userPassword', userPassword);

    const [login, loginErr] = await promiseHandler(userApi.loginUser(formData));

    if (loginErr) {
      setIsError(true);
      alert('문제가 발생하였습니다. 다시 시도해주세요.');
      return;
    }

    const { data, resultCode } = login.data;
    if (resultCode === 'SUCCESS') {
      const { accessToken, userType } = data;
      api.defaults.headers.common[`Authorization`] = `Bearer ${accessToken}`;
      setAuthTokens({ accessToken: accessToken });
      if (userType === 'keeper') {
        history.push('/');
      } else {
        alert('관리자 계정이 아닙니다');
      }
    } else {
      alert(LoginResultCode[resultCode] || '문제가 발생하였습니다.');
    }
  };

  const enterkey = (e) => {
    if (e.keyCode === 13) {
      Login();
    }
  };

  return (
    <DefaultContainer login>
      <s.CenterBox>
        <s.Title>아이젠 관리자 로그인</s.Title>
        <s.Form role="form">
          <s.InputBox>
            <s.Input
              type="username"
              value={userEmail}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              placeholder="이메일을 입력하세요."
              name="userEmail"
              onKeyUp={enterkey}
            />
          </s.InputBox>
          <s.InputBox>
            <s.Input
              type="password"
              value={userPassword}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="비밀번호를 입력하세요."
              onKeyUp={enterkey}
            />
          </s.InputBox>
          <s.LoginButton onClick={Login} type="button">
            로그인
          </s.LoginButton>
        </s.Form>
      </s.CenterBox>
    </DefaultContainer>
  );
};

export default Login;
