import styled from 'styled-components';
import { MyzzymTheme } from 'util/style/Styles';
import * as styles from 'util/style/Styles';

export const Form = styled.div`
  padding: 0 ${styles.Padding};
`;

// 제목 input
export const FormTitle = styled.input`
  width: 90%;
  margin: 0 auto;
  outline: none;

  border: none;
  border-radius: 5px;
  border: 1px solid ${MyzzymTheme.GrayBorder};

  &:focus {
    border: 1px solid ${MyzzymTheme.MyzzymColor};
  }
  ::-webkit-input-placeholder {
    font-size: 0.875rem;
  }
`;

// 설명 input
export const FormDesc = styled.textarea`
  resize: none;
  outline: none;
  line-height: 1.2rem;

  border: none;
  border-radius: 5px;
  border: 1px solid ${MyzzymTheme.GrayBorder};

  width: 90%;
  margin: 0 auto;

  &:focus {
    border: 1px solid ${MyzzymTheme.MyzzymColor};
  }
  &:hover {
    border: 1px solid ${MyzzymTheme.MyzzymColor};
  }
  ::-webkit-input-placeholder {
    font-size: 0.875rem;
  }
`;

// form에 대한 설명
export const FormExtra = styled.div`
  color: ${MyzzymTheme.Gray};
  font-weight: 400;
  font-size: 0.875rem;
  margin-bottom: 4%;
`;

export const InputDesc = styled.div`
  color: ${MyzzymTheme.Gray};
  font-weight: 400;
  font-size: 0.875rem;
  margin-bottom: 1%;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 0.813rem;
  margin-bottom: 8%;
`;

export const DateSelect = styled.input`
  border: none;
  outline: none;
  border: 1px solid ${MyzzymTheme.GrayBorder};
  border-radius: 5px;
  &:focus {
    border: 1px solid ${MyzzymTheme.MyzzymColor};
  }
  &:hover {
    border: 1px solid ${MyzzymTheme.MyzzymColor};
  }
  ::-webkit-input-placeholder {
    font-size: 0.875rem;
  }
`;

export const DateWrapper = styled.div`
  margin-left: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
`;

// 강조 표시
export const Emphasis = styled.p`
  color: ${MyzzymTheme.Red};
  padding: 0.5%;
`;
