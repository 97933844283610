/*********************************
//*** @Author : heeyeon ***
//*** @Date : 2021.01.06 ***
//*** @Title : ContactsContainer ***
*********************************/

import React, { useState, useEffect } from 'react';
import { contactsApi } from 'api/api-contacts';
import ContactsPresenter from './ContactsPresenter';

const ContactsContainer = (props) => {
  const [branchList, setBranchList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  /* if 'more detail button' clicked, show this contents... */
  const [showDetail, setShow] = useState({
    show: false,
    branch: null,
    unit: null,
  });

  /* 한번에 알림톡 보낼 리스트 like 쇼핑몰 장바구니 */
  const [pfPackage, setPackage] = useState([]);

  /* get storage list */
  useEffect(() => {
    async function fetchData() {
      let result = null;
      try {
        result = await contactsApi.contacts();
      } catch (error) {
        //console.log(error);
      } finally {
        //console.log(result);
        if (result) {
          setBranchList(result.data);
          setIsLoading(false);
          //console.log(result.data);
        } else {
          setIsLoading(false);
          setIsError(true);
        }
      }
    }
    fetchData();
  }, [branchList.length]);

  return (
    <ContactsPresenter
      branchList={branchList}
      setBranchList={setBranchList}
      isLoading={isLoading}
      isError={isError}
      showDetail={showDetail}
      setShow={setShow}
      pfPackage={pfPackage}
      setPackage={setPackage}
    />
  );
};

export default ContactsContainer;
