/**
 * @author suyeon-jung
 * @date 2021.06.21
 * @description 공지사항
 */
import React from 'react';
import DefaultContainer from 'components/DefaultContainer';
import { MyzzymTheme } from 'util/style/Styles';
import Loader from 'components/atoms/Loader';
import Error from 'components/molecules/Error';
import ENV from 'config/config';
import NoticeDetail from './detail';
import NotificationAddButton from 'views/myzzym/Notification/NotificationAddButton';

const NoticePresenter = ({ noticeList, isLoading, isError, pathname }) => {
  return (
    <DefaultContainer>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Error
          text="데이터를 불러오는데 실패하였습니다."
          color={MyzzymTheme.MyzzymColor}
          bgUrl={`${ENV.CDN_IMAGE_PATH}/icon/myzzym_nonexist.png`}
        />
      ) : noticeList.length === 0 ? (
        <>
          <NotificationAddButton pathname={pathname} />
          <Error
            text="공지사항이 존재하지 않습니다."
            color={MyzzymTheme.MyzzymColor}
            bgUrl={`${ENV.CDN_IMAGE_PATH}/icon/myzzym_nonexist.png`}
          />
        </>
      ) : (
        <NoticeDetail items={noticeList} pathname={pathname} />
      )}
    </DefaultContainer>
  );
};

export default NoticePresenter;
