/*********************************
//*** @Author : yeondo ***
//*** @Date : 2020.09.14 ***
//*** @Title : EaringPresenter ***
*********************************/

import React from 'react';
import DefaultContainer from 'components/DefaultContainer';
import Loader from 'components/atoms/Loader';
import Error from 'components/molecules/Error';
import { MyzzymTheme } from 'util/style/Styles';
import ENV from 'config/config';
import InnerContainer from 'components/molecules/inner/InnerContainer';
import SideList from 'components/molecules/sidelist/SideListContainer';
import Content from 'components/molecules/content/Content';
// 미결산 페이지
import PaymentsContainer from 'views/myzzym/Earning/unpayments/index';
// 지급요청상태 페이지
import RequestContainer from 'views/myzzym/Earning/request/index';
import CompleteContainer from 'views/myzzym/Earning/complete/index';
import RegisterMall from 'views/myzzym/Earning/content/RegisterMall';
/**
 * User를 통해서 Earning데이터 접근
 */
const EarningPresenter = ({
  curTabIndex,
  users,
  curUserIndex,
  branches,
  onChangeTab,
  onChangeUser,
  isLoading,
  isError,
}) => {
  return (
    <DefaultContainer>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Error
          // text={errorMessage}
          text={'error'}
          color={MyzzymTheme.MyzzymColor}
          bgUrl={`${ENV.CDN_IMAGE_PATH}/icon/myzzym_nonexist.png`}
        />
      ) : users.length === 0 ? (
        <Error
          text="유저가 존재하지 않습니다."
          color={MyzzymTheme.MyzzymColor}
          bgUrl={`${ENV.CDN_IMAGE_PATH}/icon/myzzym_nonexist.png`}
        />
      ) : (
        <InnerContainer>
          {/* SideList property에 맞게 props전달 */}
          <SideList
            category={'earning'}
            items={users}
            onChangeItem={onChangeUser}
            index={curUserIndex}
            filterItem={onChangeTab}
            typeIdx={curTabIndex}
          />
          <Content>
            {/* 미결산 관리 탭 */}
            {curTabIndex === 0 ? (
              <PaymentsContainer
                branches={branches}
                curUserIndex={curUserIndex}
                user={users[curUserIndex]}
              ></PaymentsContainer>
            ) : curTabIndex === 1 ? (
              <>
                {/* 지급요청 관리 탭 */}
                <RequestContainer
                  curUserIndex={curUserIndex}
                  user={users[curUserIndex]}
                ></RequestContainer>
              </>
            ) : curTabIndex === 2 ? (
              // 지급완료 확인 탭
              <>
                <CompleteContainer
                  curUserIndex={curUserIndex}
                  user={users[curUserIndex]}
                ></CompleteContainer>
              </>
            ) : (
              <>
                {/* 정보등록 탭 */}
                <RegisterMall user={users[curUserIndex]}></RegisterMall>
              </>
            )}
          </Content>
        </InnerContainer>
      )}
    </DefaultContainer>
  );
};

export default EarningPresenter;
