import { api } from './api-base';

export const payApi = {
  /**
   * @PostMapping("/pay/inicis/users/{userId}/mall")
   * 이니시스 지급대행서비스 몰 정보 등록
   */
  registerMall: (userId, mall) =>
    api.post(`/pay/inicis/users/${userId}/mall`, mall),

  /**
   * @PostMapping("/pay/incis/users/{userId}/paymentsInsert")
   * 지급요청 등록
   */
  registerPayments: (userId, payments) =>
    api.post(`/pay/inicis/users/${userId}/paymentsInsert`, payments),

  /**
   * @PostMapping("/pay/inicis/earningRecords/{earningRecordId}/paymentsDelete")
   * 지급요청 삭제
   */
  deletePayments: (earningRecordId) =>
    api.post(`/pay/inicis/earningRecords/${earningRecordId}/paymentsDelete`),

  /**
   * @PostMapping("/pay/inicis/earningRecords/{earningRecordId}/paymentsFail")
   * 지급실패 처리 - earningRecord, booking, earnings 상태 업데이트
   */
  failPayments: (earningRecordId) =>
    api.post(`/pay/inicis/earningRecords/${earningRecordId}/paymentsFail`),

  /**
   * @PostMapping("/pay/cancel")
   * 마이짐서버로 결제취소 요청 시작
   * 1. Booking status update
   * 2. Earning status update
   * 3. 결제서버 api 호출 (inicis 결제취소 api + InicisPayCancel Create)
   * ROLE - USER, ADMIN
   */
  payCancel: ({ moid, cancelAmount, msg }) =>
    api.post(`/pay/cancel`, { moid, cancelAmount, msg }),
};
