import React from 'react';
import { Space, Input, ConfigProvider } from 'antd';
import * as s from './BookingSearchStyled';
import * as styles from 'util/style/Styles';

const BookingSearchPresenter = ({ onSearchBy, searchKeys }) => {
  const { Search } = Input;
  return (
    <>
      <s.Container>
        <s.Content>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: styles.MyzzymTheme.MyzzymColor,
              },
            }}
          >
            <Space direction="horizontal">
              {searchKeys.map((searchKey, index) => (
                <Search
                  key={index}
                  placeholder={searchKey}
                  onSearch={(value) => onSearchBy(searchKey, value)}
                  enterButton="조회"
                  allowClear
                  size="large"
                />
              ))}
            </Space>
          </ConfigProvider>
        </s.Content>
      </s.Container>
    </>
  );
};

export default BookingSearchPresenter;
