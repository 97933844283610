/* eslint-disable no-unsafe-finally */
/* eslint-disable no-case-declarations */
/**
 * @author suyeon-jung
 * @date 2021.06.25
 * @description 이벤트 및 공지사항 추가를 위한 폼
 * @author skang
 * @date 2022.08.23
 * @description 퍼스널 알림 추가를 위한 폼
 */
import React, { useState, useCallback } from 'react';
import AddFormPresenter from './AddFormPresenter';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { useAuth } from 'context/auth';
import { TYPE } from 'common';

const AddFormContainer = ({ changeShowModal, formTitle }) => {
  /**
   * formTitle: 공지사항 / 이벤트 / 퍼스널
   */
  const { authTokens } = useAuth();
  const decodedAccessToken =
    authTokens && authTokens.accessToken
      ? jwtDecode(authTokens.accessToken)
      : null;
  const [form, setForm] = useState({
    title: '',
    desc: '',
    images: [],
    writer: decodedAccessToken
      ? `${decodedAccessToken?.userLastName}${decodedAccessToken?.userFirstName}`
      : '익명',
    startDate: moment(),
    endDate: formTitle === '이벤트' ? moment() : 0,
    category: 'MARKETING',
  });
  const { images, startDate } = form;
  const [isImgLoading, setIsImgLoading] = useState(false);
  const onChange = useCallback(
    (e) => {
      const { name, value, files } = e.target;
      switch (name) {
        case 'imageList':
          files
            ? setForm({
                ...form,
                images: files,
              })
            : setForm({
                ...form,
                images: [],
              });
          break;
        case 'startDate':
          // 사용자가 설정한 시작일이 오늘보다 이후인지 확인
          const now = moment();
          if (moment(value).diff(now, 'days') < 0) {
            alert('오늘 이후의 시작일을 입력해야 합니다.');
          } else {
            setForm({
              ...form,
              [name]: moment(value),
            });
          }
          break;
        case 'endDate':
          // 사용자가 설정한 종료일이 시작일보다 미래인지 확인
          const start = startDate ? moment(startDate) : moment();
          if (moment(value).diff(start, 'days') < 0) {
            alert('시작일 이후의 종료일을 입력해야 합니다.');
          } else {
            setForm({
              ...form,
              [name]: moment(value),
            });
          }
          break;
        case 'userIds':
          setForm({
            ...form,
            [name]: value,
          });
          break;
        case 'category':
          console.log(value, '<-value in category');
          setForm({
            ...form,
            [name]: value,
          });
          break;
        default:
          setForm({
            ...form,
            [name]: value,
          });
      }
    },
    [form],
  );

  // 여기서의 event는 알림(이벤트)를 의미하는 것이 아님, 이벤트 서버에서 새로운 이벤트를 만든다는 의미
  const createNotification = async (images = []) => {
    alert('현재 지원하지 않는 기능입니다.');
  };

  return (
    <AddFormPresenter
      form={form}
      isImgLoading={isImgLoading}
      onChange={onChange}
      // getImgUrl={getImgUrl}
      createNotification={createNotification}
      changeShowModal={changeShowModal}
      formTitle={formTitle}
    />
  );
};

export default AddFormContainer;
