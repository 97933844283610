import React from 'react';
import InnerContainer from 'components/molecules/inner/InnerContainer';
import SideList from 'components/molecules/sidelist/SideListContainer';
import NoticeContent from './content';

const NoticeDetailPresenter = ({
  items,
  currentItem,
  onChangeItem,
  filterItem,
  index,
  typeIdx,
  pathname,
}) => {
  return (
    <InnerContainer>
      <SideList
        category={'notice'}
        items={items}
        index={index}
        typeIdx={typeIdx}
        onChangeItem={onChangeItem}
        filterItem={filterItem}
      />
      <NoticeContent item={currentItem} pathname={pathname} />
    </InnerContainer>
  );
};

export default NoticeDetailPresenter;
