import React from 'react';
import * as s from './SideListStyled';
import BookingType from './type/BookingTypePresenter';
import List from './list';

const SideListPresenter = ({
  category,
  items,
  filterList,
  onChangeItem,
  index,
  filterItems,
  selectedFilter,
}) => {
  return (
    <s.Container>
      {category && (
        <BookingType
          selectedFilter={selectedFilter}
          filterList={filterList}
          filterItems={filterItems}
        />
      )}
      <List
        category={category}
        items={items}
        onChangeItem={onChangeItem}
        index={index}
      />
    </s.Container>
  );
};

export default SideListPresenter;
