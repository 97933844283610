/**
 * @author dong
 * @date 2021.12.23
 * @description 결산 페이지 업데이트 - Branch, 날짜 필터 등등
 */
import React, { useState, useEffect } from 'react';
import EarningPresenter from './EarningPresenter';
import { userApi } from 'api/api-user';
import { branchApi } from 'api/api-branch';
import useDidMountEffect from 'components/useDidMountEffect';

/**
 * 결산 페이지 시나리오
 * 1. searchUsers: Users 조회 - 기본 정보만
 * 2. searchBranchesByUser(userId): 선택한 User가 가지고 있는 Branches 조회 - 기본 정보만
 * 3. 선택한 multiple Branch에 대하여 searchBookingsByBranch(branchId): 선택한 Branch가 가지고 있는 Bookings 조회 - Earning 정보 포함
 * 4. Bookings 정보를 통해 결산정보 출력
 * ?? 지점(Branch)이 여러개이면 한번 선택한 Branch에 따라서 지급요청을 한다?? -> 위 3번에서 Branch를 여러개 선택할 수 있도록
 * 이전 선택했던 데이터들에 대하여 저장은 하지 말기, 새로 api call
 */
const EarningContainer = () => {
  /**
   * 0. 미결산
   * 1. 지급요청중(지급완료업데이트 및 삭제요청)
   * 2. 지급완료
   */
  const [curTabIndex, setCurTabIndex] = useState(0);

  /**
   * 연관관계를 제외한 Users 조회
   */
  const [users, setUsers] = useState([]);

  /**
   * 현재 선택한 User
   */
  const [curUserIndex, setCurUserIndex] = useState();

  /**
   * 선택한 User가 가지고 있는 Branches
   */
  const [branches, setBranches] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  /**
   * Tab onChange
   */
  const onChangeTab = (e, index) => {
    setCurTabIndex(index);
  };

  /**
   * User onChange
   */
  const onChangeUser = (index) => {
    setCurUserIndex(index);
  };

  /**
   * 처음 기본정보만 가진 Users fetch
   */
  const fetchUsers = async () => {
    let result = null;
    try {
      // let params = {
      //   origin: true
      // };
      ({ data: result } = await userApi.searchUsers());
      setUsers(result);
      setCurUserIndex(0);
    } catch (error) {
      console.log(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * User가 가진 기본정보만 가진 Branches 조회
   */
  const fetchBranches = async () => {
    let result = null;
    try {
      let params = {
        origin: true,
      };
      ({ data: result } = await branchApi.searchBranchesByUser(
        users[curUserIndex].id,
        params,
      ));
      // console.log(result);
      setBranches(result);
    } catch (error) {
      console.log(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * user 변경에 따른 Branches 조회
   */
  useDidMountEffect(() => {
    fetchBranches();
  }, [curUserIndex]);

  /**
   * 처음 기본정보만 가진 Users 조회
   */
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <EarningPresenter
      curTabIndex={curTabIndex}
      users={users}
      curUserIndex={curUserIndex}
      branches={branches}
      onChangeTab={onChangeTab}
      onChangeUser={onChangeUser}
      isLoading={isLoading}
      isError={isError}
    />
  );
};

export default EarningContainer;
