import styled from 'styled-components';
import * as styles from 'util/style/Styles';

// notice title
export const TitleContainer = styled.div`
  font-size: 1.125rem;
  padding: ${styles.Padding};
  ${styles.FlexBetween};
`;
export const Title = styled.div`
  flex: 10;
`;

export const HighLight = styled.strong`
  font-weight: 600;
`;

// notice 정보
export const NoticeItem = styled.li`
  ${styles.FlexStart};
  padding: ${styles.Padding};
`;

export const NoticeItemTitle = styled.span`
  flex: 2;
  color: ${styles.MyzzymTheme.Gray};
`;

export const NoticeItemDesc = styled.span`
  flex: 6;
`;

export const NoticeImage = styled.img`
  max-width: 100%;
  height: auto;
  padding: ${styles.Padding};
`;
