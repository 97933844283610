import React, { useState } from 'react';
import * as s from '../ExamineContentStyled';
import ENV from 'config/config';
import Modal from 'components/molecules/modal/Modal';
import Error from 'components/molecules/Error';

const UnitPresenter = ({ item, units, handleCheckbox }) => {
  // 이미지 자세히 보기 모달
  const [showModal, setShowModal] = useState(false);
  const [unitImgIndex, setUnitImgIndex] = useState(-1);
  const [unitIndex, setUnitIndex] = useState(-1);
  const changeShowModal = () => setShowModal(!showModal);
  return (
    <s.BranchContainer unit>
      {units && units.length === 0 ? (
        <Error
          text={'유닛 없음'}
          bgUrl={`${ENV.CDN_IMAGE_PATH}/icon/myzzym_nonexist.png`}
        />
      ) : (
        units.map((unit, idx) => (
          <s.UnitContainer key={idx}>
            <s.TitleContainer>
              <s.Title>
                <s.HighLight>{unit.id}.</s.HighLight> {unit.unitName}
              </s.Title>
              <s.BranchInput
                type="checkbox"
                name={'unit ' + unit.id + ' ' + unit.unitName}
                onClick={handleCheckbox}
              />
            </s.TitleContainer>

            {/* 유닛 수량 */}
            <s.BranchItem unit>
              <s.BranchItemTitle>수량</s.BranchItemTitle>
              <s.BranchItemDesc>{unit.count}개</s.BranchItemDesc>
            </s.BranchItem>

            {/* 유닛 가격 */}
            <s.BranchItem unit>
              <s.BranchItemTitle>가격</s.BranchItemTitle>
              <s.BranchItemDesc>
                {unit.priceValue === null ? '' : unit.priceValue}
                {unit.priceValue !== null && unit.priceType === 'WON'
                  ? `원 / ${unit.pricePeriod}`
                  : unit.priceValue !== null && unit.priceType}
              </s.BranchItemDesc>
            </s.BranchItem>

            {/* 크기*/}
            <s.BranchItem unit>
              <s.BranchItemTitle>수량</s.BranchItemTitle>
              <s.BranchItemDesc>
                {unit.width} cm(가로) x {unit.height} cm(세로) x {unit.depth}
                cm(높이) / {unit.pyung.toFixed(3)}평 / {unit.square.toFixed(3)}
                스퀘어
              </s.BranchItemDesc>
            </s.BranchItem>

            {/* 소개 */}
            <s.BranchItem unit>
              <s.BranchItemTitle>소개</s.BranchItemTitle>
              <s.BranchItemDesc>{unit.unitDescription}</s.BranchItemDesc>
            </s.BranchItem>

            {/* 유닛 사진 */}
            <s.BranchItem unit>
              <s.BranchItemTitle>유닛 사진</s.BranchItemTitle>
              <s.BranchItemDesc>
                <s.ImgGroup>
                  <s.ImgContainer>
                    {unit.unitImages.map((image, index) => (
                      <s.ImgWrapper key={index}>
                        <s.Img
                          src={`${ENV.CDN_IMAGE_PATH}/branch/${item.id}/${unit.id}/${image.fileName}`}
                          onClick={() => {
                            console.log(
                              `${ENV.CDN_IMAGE_PATH}/branch/${item.id}/${unit.id}/${image.fileName}`,
                            );
                            setUnitImgIndex(index);
                            setUnitIndex(idx);
                            changeShowModal();
                          }}
                        />
                      </s.ImgWrapper>
                    ))}
                  </s.ImgContainer>
                </s.ImgGroup>
              </s.BranchItemDesc>
            </s.BranchItem>
          </s.UnitContainer>
        ))
      )}
      {showModal && (
        <Modal
          image
          show={true}
          changeShow={changeShowModal}
          header={'이미지 자세히 보기'}
          onClickFooterButton={changeShowModal}
          footerButtonText={'닫기'}
        >
          <s.ExpandImgWrapper>
            <s.Img
              alt=""
              src={`${ENV.CDN_IMAGE_PATH}/branch/${item.id}/${units[unitIndex].id}/${units[unitIndex].unitImages[unitImgIndex].fileName}`}
            />
          </s.ExpandImgWrapper>
        </Modal>
      )}
    </s.BranchContainer>
  );
};

export default UnitPresenter;
