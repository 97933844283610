import React, { useState, useEffect } from 'react';
import { examineApi } from 'api/api-examine';
import ExaminePresenter from './ExaminePresenter';

const ExamineContainer = () => {
  const [branchList, setBranchList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchData = async () => {
    let result = null;

    try {
      // 0 : 검수필요,검수중  / 2 : 검수반려
      let params = new URLSearchParams();

      params.append('isExamines', 0);
      params.append('isExamines', 2);
      params.append('page', 0);
      params.append('size', 200);

      result = await examineApi.examines(params);
    } catch (error) {
      console.log(error);
    } finally {
      if (result) {
        setBranchList(result.data.content);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsError(true);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ExaminePresenter
      branchList={branchList}
      isLoading={isLoading}
      isError={isError}
    />
  );
};

export default ExamineContainer;
