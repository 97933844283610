/**
 * @author ydoni
 * @date 2021.07.20
 * @description side list
 */

import React, { useEffect, useState } from 'react';
import SideListPresenter from './SideListPresenter';

const SideListContainer = ({
  category,
  items,
  onChangeItem,
  index,
  filterItem,
  typeIdx,
}) => {
  const [list, setList] = useState([]);

  // useEffect(() => {
  //   if (items === 'undefined') {
  //     items = [];
  //   }
  // }, [category]);

  useEffect(() => {
    if (category === 'examine') {
      setList([
        {
          name: '전체',
        },
        {
          name: '검수중',
        },
        {
          name: '검수반려',
        },
      ]);
    } else if (category === 'earning') {
      setList([
        {
          name: '미결산',
        },
        {
          name: '지급요청상태',
        },
        {
          name: '지급완료',
        },
        {
          name: '정보등록',
        },
      ]);
    } else if (
      category === 'notice' ||
      category === 'event' ||
      category === 'personal'
    ) {
      setList([
        {
          name: '전체',
        },
        {
          name: '푸시미설정',
        },
        {
          name: '푸시설정',
        },
      ]);
    }
  }, [category]);

  return (
    <SideListPresenter
      category={category}
      list={list}
      onChangeItem={onChangeItem}
      index={index}
      filterItem={filterItem}
      typeIdx={typeIdx}
      items={items}
    />
  );
};

export default SideListContainer;
