import React, { useState } from 'react';
import BookingDetailPresenter from './BookingDetailPresenter';

export const getStatusString = (bookingStatusCode) => {
  switch (bookingStatusCode) {
    case 2:
      return '결제완료';
    case 4:
      return '결제취소';
    default:
      return '';
  }
};

const StatusStringList = [2, 4].map((statusCode) =>
  getStatusString(statusCode),
);

export const AllStatusString = '전체';

const filterList = [AllStatusString, ...StatusStringList];

const BookingDetailContainer = ({ bookingList }) => {
  const [currentItem, setCurrentItem] = useState(bookingList[0]);

  return (
    <BookingDetailPresenter
      bookingList={bookingList}
      filterList={filterList}
      currentItem={currentItem}
      setCurrentItem={setCurrentItem}
    />
  );
};

export default BookingDetailContainer;
