import styled from 'styled-components';
import * as styles from 'util/style/Styles';

// event title
export const TitleContainer = styled.div`
  font-size: 1.125rem;
  padding: ${styles.Padding};
  ${styles.FlexBetween};
`;
export const Title = styled.div`
  flex: 10;
`;

export const HighLight = styled.strong`
  font-weight: 600;
`;

// event 정보
export const EventItem = styled.li`
  ${styles.FlexStart};
  padding: ${styles.Padding};
`;

export const EventItemTitle = styled.span`
  flex: 2;
  color: ${styles.MyzzymTheme.Gray};
`;

export const EventItemDesc = styled.span`
  flex: 6;
`;

export const EventImage = styled.img`
  max-width: 100%;
  height: auto;
  padding: ${styles.Padding};
`;
