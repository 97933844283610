import styled from 'styled-components';
import * as styles from 'util/style/Styles';

export const MyzzymTheme = {
  MyzzymColor: '#1bc1bb',
  MyzzymNoTrans: '#f2fbfc',
  MyzzymFormColor: '#00848A',
  MyzzymOpacity: 'rgba(27,193,187,0.03)',
  LightMyzzymColor: 'rgba(27,193,187,0.05)',
  LightGray: '#fafafa',
  Gray: '#8d8d8d',
  GrayBorder: '#ededed',
  DeepGray: '#757575',
  Black: '#303030',
  PlaceHolderColor: '#AFAFAF',
  Blue: 'rgb(36, 167, 255)',
  White: '#FFFFFF',
  Green: '#49C219',
  Red: '#ff4949',
  Orange: '#FDAC2D',
};

export const TitleContainer = styled.div`
  font-size: 1.125rem;
  padding: ${styles.Padding};
  ${styles.FlexBetween};
`;
export const Title = styled.div`
  flex: 10;
`;
export const HighLight = styled.strong`
  font-weight: 600;
`;

/**
 * 필터 영역
 * 1. Branches
 * 2. startDate + endDate
 */
export const FilterContainer = styled.div`
  ${styles.FlexBetween};
`;
/**
 * Branch 선택 필터 양약
 */
export const BranchFilterDiv = styled.div`
  flex: 3;
  margin: 5px;
`;
export const StartDateFilterDiv = styled.div`
  flex: 3;
  margin: 5px;
  /* padding: 5px; */
  text-align: center;
  border: solid;
  border-radius: 1px;
  border-width: 1.5px;
  border-color: gray;
`;
export const EndDateFilterDiv = styled.div`
  flex: 3;
  margin: 5px;
  /* padding: 5px; */
  text-align: center;
  border: solid;
  border-radius: 1px;
  border-width: 1.5px;
  border-color: gray;
`;
export const PaymentsStatusFilterDiv = styled.div`
  flex: 3;
  margin: 5px;
`;

/**
 * earnings 목록 나타낼 ScrollView
 */
export const EarningsDiv = styled.div`
  scroll-behavior: smooth;
  height: 800px;
  overflow-y: scroll;
  margin: 10px;
  border: solid;
  border-radius: 1px;
  border-width: 3px;
  border-color: ${MyzzymTheme.MyzzymColor};
`;

/**
 * 결산내역 표시 영역
 */
export const ItemContainer = styled.div`
  ${styles.FlexBetween};
  margin: 10px;
  padding: 10px;
  border: solid;
  border-radius: 1px;
  border-width: 3px;
  border-color: black;
`;
export const ItemCheck = styled.div`
  flex: 1;
`;
export const ItemCheckButton = styled.bu;

export const ItemBookingId = styled.div`
  flex: 2;
  text-align: center;
`;
export const ItemEarningId = styled.div`
  flex: 2;
  text-align: center;
`;
export const ItemCreatedDate = styled.div`
  flex: 3;
  text-align: center;
`;
export const ItemBookingRange = styled.div`
  flex: 4;
  text-align: center;
`;
export const ItemAmount = styled.div`
  flex: 3;
  text-align: center;
`;

export const ItemStatus = styled.div`
  flex: 1;
  text-align: center;
`;

// 총 금액 / 지급일 입력 / 지급버튼
export const PaymentsContainer = styled.div`
  ${styles.FlexBetween};
`;
export const PaymentsAmountDiv = styled.div`
  flex: 4;
  margin: 5px;
  padding: 10px;
  border: solid;
  border-radius: 1px;
  border-width: 1px;
  border-color: gray;
  text-align: center;
`;
export const PaymentsAmountText = styled.p``;

export const PaymentsDateDiv = styled.div`
  flex: 4;
  margin: 5px;
  border: solid;
  border-radius: 1px;
  border-width: 1px;
  border-color: gray;
  text-align: center;
`;
