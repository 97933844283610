/*********************************
//*** @Author : HEEYEON ***
//*** @Date : 2021.01.07 ***
//*** @Title : show list ***
*********************************/

import React from 'react';
import InnerContainer from 'components/molecules/inner/InnerContainer';
import SideList from 'components/molecules/sidelist/SideListContainer';
import ContactContent from './content';

const ContactsDetailPresenter = ({
  items,
  index,
  currentItem,
  onChangeItem,
}) => {
  return (
    <InnerContainer>
      <SideList
        items={items}
        category={'contact'}
        index={index}
        onChangeItem={onChangeItem}
      />
      <ContactContent item={currentItem} />
    </InnerContainer>
  );
};

export default ContactsDetailPresenter;
