import React from 'react';
import * as s from './NoticeContentStyled';
import Content from 'components/molecules/content/Content';
import NotificationAddButton from 'views/myzzym/Notification/NotificationAddButton';
import NotificationDeleteButton from 'views/myzzym/Notification/NotificationDeleteButton';
import NotificationReserveButton from 'views/myzzym/Notification/NotificationReserveButton';
import * as format from 'util/fomatter/Formatter';

const NoticeContentPresenter = ({ item, pathname }) => {
  return (
    <Content>
      <s.TitleContainer>
        <s.Title>
          <s.HighLight>{item?.title}</s.HighLight>
        </s.Title>
        <NotificationReserveButton item={item} />
        <NotificationDeleteButton item={item} pathname={pathname} />
        <NotificationAddButton pathname={pathname} />
      </s.TitleContainer>
      {item?.images &&
        item?.images.map((image, idx) => (
          <s.NoticeImage
            src={image}
            alt=""
            key={idx}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        ))}

      <s.NoticeItem>
        <s.NoticeItemTitle>category</s.NoticeItemTitle>
        <s.NoticeItemDesc>{item?.category}</s.NoticeItemDesc>
      </s.NoticeItem>
      <s.NoticeItem>
        <s.NoticeItemTitle>공지사항 제목</s.NoticeItemTitle>
        <s.NoticeItemDesc>{item?.title}</s.NoticeItemDesc>
      </s.NoticeItem>
      <s.NoticeItem>
        <s.NoticeItemTitle>공지사항 설명</s.NoticeItemTitle>
        {/* <s.NoticeItemDesc>{item?.desc}</s.NoticeItemDesc> */}
        <s.NoticeItemDesc>
          <div dangerouslySetInnerHTML={{ __html: `${item?.desc}` }} />
        </s.NoticeItemDesc>
      </s.NoticeItem>
      <s.NoticeItem>
        <s.NoticeItemTitle>공지사항 게시일시</s.NoticeItemTitle>
        <s.NoticeItemDesc>
          {item?.startDate && format.timeToFormat(item.startDate)}
        </s.NoticeItemDesc>
      </s.NoticeItem>
      <s.NoticeItem>
        <s.NoticeItemTitle>푸시 일시</s.NoticeItemTitle>
        <s.NoticeItemDesc>
          {item?.pushDate && format.isInitialDate(item.pushDate)
            ? '설정 안됨'
            : item?.pushDate && format.timeToFormat(item.pushDate)}
        </s.NoticeItemDesc>
      </s.NoticeItem>
      {/* <s.NoticeItem>
        <s.NoticeItemTitle>푸시 상태</s.NoticeItemTitle>
        <s.NoticeItemDesc>{item.hasSent ? "푸시완료" : "미전송"}</s.NoticeItemDesc>
      </s.NoticeItem> */}
      <s.NoticeItem>
        <s.NoticeItemTitle>작성자</s.NoticeItemTitle>
        <s.NoticeItemDesc>{item?.writer}</s.NoticeItemDesc>
      </s.NoticeItem>
      <s.NoticeItem>
        <s.NoticeItemTitle>공지사항 등록일시</s.NoticeItemTitle>
        <s.NoticeItemDesc>
          {item?.createdAt && format.timeToFormat(item.createdAt)}
        </s.NoticeItemDesc>
      </s.NoticeItem>
    </Content>
  );
};

export default NoticeContentPresenter;
