import React from 'react';
import NoticeContentPresenter from './NoticeContentPresenter';

const NoticeContentContainer = ({ item, pathname }) => {
  console.log('---item, ', item);
  console.log('---pathname, ', pathname);
  return <NoticeContentPresenter item={item} pathname={pathname} />;
};

export default NoticeContentContainer;
