/**
 * @author suyeon-jung
 * @date 2021.07.22
 * @description 이벤트 및 공지사항 푸시알림 예약을 위한 폼
 */
import React from 'react';
import * as s from '../FormStyled';
import Modal from 'components/molecules/modal/Modal';
import moment from 'moment';

const ReserveFormPresenter = ({
  changeShowModal,
  pushDate,
  pushTime,
  onChange,
  reserveEvent,
}) => {
  return (
    <Modal
      show={true}
      changeShow={changeShowModal}
      header={'푸시알림 예약!'}
      onClickFooterButton={reserveEvent}
      footerButtonText={'예약하기!'}
    >
      <s.Form>
        <s.InputWrapper>
          날짜
          <s.Emphasis>*</s.Emphasis>
          <s.DateWrapper>
            <s.DateSelect
              placeholder="날짜"
              type="date"
              name="pushDate"
              value={moment(pushDate).format('YYYY-MM-DD')}
              onChange={onChange}
              required
            />
          </s.DateWrapper>
        </s.InputWrapper>
        <s.InputWrapper>
          시간<s.Emphasis>*</s.Emphasis>
          <s.DateWrapper>
            <s.DateSelect
              type="time"
              onChange={onChange}
              name="pushTime"
              value={pushTime}
            />
          </s.DateWrapper>
          &nbsp;
        </s.InputWrapper>
      </s.Form>
    </Modal>
  );
};

export default ReserveFormPresenter;
