/**
 *
 * @author ydoni
 * @date 2021.07.20
 * @description side list
 */

import React from 'react';
import * as s from './SideListStyled';
import Type from './type/TypePresenter';
import List from './list';

const SideListPresenter = ({
  category,
  items,
  list,
  onChangeItem,
  index,
  filterItem,
  typeIdx,
}) => {
  return (
    <s.Container>
      {(category === 'examine' ||
        category === 'earning' ||
        category === 'notice' ||
        category === 'event' ||
        category === 'personal') && (
        <Type typeIdx={typeIdx} list={list} filterItem={filterItem} />
      )}
      <List
        category={category}
        items={items}
        onChangeItem={onChangeItem}
        index={index}
      />
    </s.Container>
  );
};

export default SideListPresenter;
