import React from 'react';
import * as s from './BookingContentStyled';
import Content from 'components/molecules/content/Content';
import { getStatusString } from '../BookingDetailContainer';
import { dateToFormat } from 'util/fomatter/Formatter';

const BookingContentPresenter = ({ item, cancelBooking }) => {
  if (!item) return <s.Title></s.Title>;

  const bookingStatus = getStatusString(item.status);
  return (
    <Content>
      <s.TitleContainer>
        <s.Title>
          <s.HighLight>{item.id}.</s.HighLight>
        </s.Title>
        <s.ButtonContainer>
          <s.Button
            disable={bookingStatus !== '결제완료'}
            onClick={cancelBooking}
          >
            예약 취소하기
          </s.Button>
        </s.ButtonContainer>
      </s.TitleContainer>
      <s.BranchItem>
        <s.BranchItemTitle>bookingId</s.BranchItemTitle>
        <s.BranchItemDesc>{item.id}</s.BranchItemDesc>
      </s.BranchItem>
      <s.BranchItem>
        <s.BranchItemTitle>예약일</s.BranchItemTitle>
        <s.BranchItemDesc>{dateToFormat(item.createdDate)}</s.BranchItemDesc>
      </s.BranchItem>
      <s.BranchItem>
        <s.BranchItemTitle>결제금액</s.BranchItemTitle>
        <s.BranchItemDesc>{item.amount}원</s.BranchItemDesc>
      </s.BranchItem>
      <s.BranchItem>
        <s.BranchItemTitle>status</s.BranchItemTitle>
        <s.BranchItemDesc>{getStatusString(item.status)}</s.BranchItemDesc>
      </s.BranchItem>
      <s.BranchItem>
        <s.BranchItemTitle>userId</s.BranchItemTitle>
        <s.BranchItemDesc>{item.user.id}</s.BranchItemDesc>
      </s.BranchItem>
      <s.BranchItem>
        <s.BranchItemTitle>userName</s.BranchItemTitle>
        <s.BranchItemDesc>
          {item.user.userLastName}
          {item.user.userFirstName}
        </s.BranchItemDesc>
      </s.BranchItem>
    </Content>
  );
};

export default BookingContentPresenter;
