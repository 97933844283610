import React, { useEffect, useState } from 'react';
import PaymentsPresenter from './PaymentsPresenter';

import bookingApi from 'api/api-booking';
import { payApi } from 'api/api-pay';
import moment from 'moment';

/**
 * 1. Branch 필터 -> 선택한 Branch id들에 해당하는 Booking
 * 2. 날짜 필터 -> 해당 날짜 사이에 해당하는 Booking
 */
const PaymentsContainer = ({ branches, curUserIndex, user }) => {
  /**
   * 1. Branch 필터에서 선택한 Branch infos
   * react-select에서 사용하는 Select의 item 객체 형식인 {value: branchId, label: branchName} 형태로 이루어짐
   * 추후 api call 시 value를 사용하기
   */
  const [selectedBranchInfos, setSelectedBranchInfos] = useState([]);

  /**
   * 기존꺼 포함 + 선택한 value가 들어옴 -> [{old}, {old}, {new}]
   */
  const onChangeBranchInfos = (selectedInfos) => {
    // {value: branchId, label: branchName}
    console.log('selectedInfos', selectedInfos);
    console.log(selectedInfos.filter((info) => info.value === -1).length);
    /**
     * 전체 고른 경우
     */
    if (selectedInfos.filter((info) => info.value === -1).length > 0) {
      setSelectedBranchInfos(
        branches.map((branch) => {
          return { value: branch.id, label: branch.branchName };
        }),
      );
      return;
    }
    setSelectedBranchInfos(selectedInfos);
  };

  /**
   * 2. 날짜 필터 startDate, endDate (YYYY-MM-DD)
   */
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChangeStartDate = (date) => {
    // console.log(date);
    setStartDate(date);
  };
  const onChangeEndDate = (date) => {
    // console.log(date);
    setEndDate(date);
  };

  /**
   * Branch 가지고 있는 Bookings들의 모든 합 -> booking.earning && booking.earning.payStatus === 0인것만 필터링
   */
  const [bookings, setBookings] = useState([]);

  /**
   * 지급요청 날짜 String YYYY-MM-DD
   */
  const [paymentsDate, setPaymentsDate] = useState(new Date());
  const onChangePaymentsDate = (date) => {
    setPaymentsDate(date);
  };

  const getBookingIds = () => {
    let tempIds = [];
    for (let i = 0; i < bookings.length; i++) {
      tempIds.push(bookings[i].id);
    }
    return tempIds;
  };

  const getTotalAmount = () => {
    let tempTotalAmount = 0;
    for (let i = 0; i < bookings.length; i++) {
      tempTotalAmount += bookings[i].earning.earningAmount;
    }
    return tempTotalAmount;
  };

  const executePayments = async () => {
    let confirm = window.confirm('결산하시겠습니까?');
    if (confirm) {
      /**
       * backend api 호출
       * 1. earnings에 대하여 payStatus 업데이트 (0 -> 1(지급요청))
       * 2. 이니시스 지급대행서비스 측에 지급요청 호출
       */
      let payments = {
        dtPay: moment(paymentsDate).format('YYYYMMDD'), // (String)지급일자 ex. 20210803(YYYYMMDD)
        amtSupply: String(getTotalAmount()), // 지급액
        clService: '0', // 차수구분이 머지?? 2: 당일지급
        clStatus: 'I', // 요청상태 I: 등록, D: 삭제 -> 미결산인경우는 무조건 등록 요청
        // earningIds: earningIds,
        bookingIds: getBookingIds(),
        earningStartDate: moment(startDate).format('YYYY-MM-DD'), // LocalDate
        earningEndDate: moment(endDate).format('YYYY-MM-DD'), // LocalDate
      };
      let result = null;
      try {
        result = await payApi.registerPayments(user.id, payments);
        alert('결산요청되었습니다.');
        window.location.reload();
      } catch (error) {
        let errorResponse = error.response;
        if (errorResponse) {
          console.log(errorResponse);
          alert('에러가 발생하였습니다.' + errorResponse.data);
        } else {
          // api 호출에 대해 서버 에러가 아닌 다른 이유의 에러인겨웅 -> ex. 네트워크 에러
          alert('에러가 발생하였습니다.');
        }
      }
    }
  };

  /**
   * 조회 버튼 클릭 시 선택한 BranchIds + startDate + endDate 필터링 된 Bookings 조회 하기
   * Booking startDate 기준 조회
   */
  const fetchBookings = async () => {
    let params = new URLSearchParams();
    params.append(
      'bookingStartSearchStartDate',
      moment(startDate).format('YYYY-MM-DD'),
    );
    params.append(
      'bookingStartSearchEndDate',
      moment(endDate).format('YYYY-MM-DD'),
    );
    params.append('statuses', 2);
    params.append('earningStatus', 0);

    /**
     * 선택한 Branch ids를 이용하여 각 api call
     */
    Promise.all(
      selectedBranchInfos.map((branchInfo) => {
        let branchId = branchInfo.value;
        return bookingApi
          .searchBookingsByBranch(branchId, params)
          .then((res) => res.data);
      }),
    )
      .then((results) => {
        // Backend에서 earning이 있는 Booking만 조회된다.
        console.log(results); // -> [[], [], [], .....] 형태
        /**
         * bookings 합치기
         */
        let tempBookings = [];
        for (let i = 0; i < results.length; i++) {
          let result = results[i];
          for (let j = 0; j < result.length; j++) {
            tempBookings.push(result[j]);
          }
        }
        setBookings(tempBookings);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setSelectedBranchInfos([]);
    setStartDate(new Date());
    setEndDate(new Date());
    setBookings([]);
    setPaymentsDate(new Date());
  }, [curUserIndex]);

  return (
    <PaymentsPresenter
      user={user}
      branches={branches}
      curUserIndex={curUserIndex}
      selectedBranchInfos={selectedBranchInfos}
      startDate={startDate}
      endDate={endDate}
      bookings={bookings}
      paymentsDate={paymentsDate}
      onChangeBranchInfos={onChangeBranchInfos}
      onChangeStartDate={onChangeStartDate}
      onChangeEndDate={onChangeEndDate}
      onChangePaymentsDate={onChangePaymentsDate}
      fetchBookings={fetchBookings}
      getTotalAmount={getTotalAmount}
      executePayments={executePayments}
    ></PaymentsPresenter>
  );
};

export default PaymentsContainer;
