/**
 * @description: 처음 초기 렌더링때 useEffect 실행을 막기위한 custom Hook
 */
import React, { useEffect, useRef } from 'react';

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

export default useDidMountEffect;
