import React, { useEffect, useState } from 'react';
import * as s from './PaymentsStyled';
import * as styles from 'util/style/Styles';
import { priceToFormat } from 'util/fomatter/Formatter';
import Button from 'components/atoms/Button';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { payStatusToString } from 'util/fomatter/Formatter';

import Select from 'react-select';
import moment from 'moment';

const PaymentsPresenter = ({
  user,
  branches,
  curUserIndex,
  selectedBranchInfos,
  startDate,
  endDate,
  bookings,
  paymentsDate,

  onChangeBranchInfos,
  onChangeStartDate,
  onChangeEndDate,
  onChangePaymentsDate,

  fetchBookings,
  getTotalAmount,
  executePayments,
}) => {
  return (
    <>
      <s.TitleContainer>
        <s.Title>
          <s.HighLight>
            미결산 → 지급요청 (날짜 기간 안에 Booking의 startDate포함된 내역
            조회됨)
          </s.HighLight>
        </s.Title>
      </s.TitleContainer>

      {/* 필터 영역 */}
      <s.FilterContainer>
        <>
          {/* Branch 선택 */}
          <s.BranchFilterDiv>
            <Select
              key={curUserIndex}
              defaultValue={null}
              onChange={onChangeBranchInfos}
              value={selectedBranchInfos}
              options={
                // 전체 선택한 경우는 선택목록에서 전체 없도록
                selectedBranchInfos.length === branches.length
                  ? branches.map((branch) => {
                      return { value: branch.id, label: branch.branchName };
                    })
                  : [{ value: -1, label: '전체' }].concat(
                      branches.map((branch) => {
                        return { value: branch.id, label: branch.branchName };
                      }),
                    )
              }
              isMulti
              isSearchable
            ></Select>
          </s.BranchFilterDiv>

          {/* 시작날짜 선택 */}
          <s.StartDateFilterDiv>
            <DatePicker
              selected={startDate}
              onChange={onChangeStartDate}
              dateFormat={'yyyy-MM-dd'}
            ></DatePicker>
            (Booking.startDate 기준 조회 시작일)
          </s.StartDateFilterDiv>

          {/* 끝날짜 선택 */}
          <s.EndDateFilterDiv>
            <DatePicker
              selected={endDate}
              onChange={onChangeEndDate}
              dateFormat={'yyyy-MM-dd'}
            ></DatePicker>
            (Booking.startDate 기준 조회 종료일)
          </s.EndDateFilterDiv>

          {/* 조회 버튼 */}
          <Button text={'조회'} onClick={fetchBookings}></Button>
        </>
      </s.FilterContainer>

      {/* 상세 내역 */}
      <s.EarningsDiv>
        <s.ItemContainer>
          {/* 예약 No */}
          <s.ItemBookingId>예약(Boooking)No.</s.ItemBookingId>
          {/* 결산 No */}
          <s.ItemEarningId>결산(Earning)No.</s.ItemEarningId>
          {/* 결제일 */}
          <s.ItemCreatedDate>결제일</s.ItemCreatedDate>
          {/* 이용시작일 ~ 이용종료일 */}
          <s.ItemBookingRange>이용날짜</s.ItemBookingRange>
          {/* 결산 금액 */}
          <s.ItemAmount>결산금액</s.ItemAmount>
          {/* 결산 상태 */}
          <s.ItemStatus>결산 상태</s.ItemStatus>
        </s.ItemContainer>
        {bookings.map((booking) => (
          <>
            {/* 예약-결산 내역  -> ScrollView로 업데이트*/}
            <s.ItemContainer>
              {/* 예약 No */}
              <s.ItemBookingId>{booking.id}</s.ItemBookingId>
              {/* 결산 No */}
              <s.ItemEarningId>{booking.earning.id}</s.ItemEarningId>
              {/* 결제일 */}
              <s.ItemCreatedDate>
                {booking.earning.createdDate}
              </s.ItemCreatedDate>
              {/* 이용시작일 ~ 이용종료일 */}
              <s.ItemBookingRange>
                {moment(booking.startDate).format('YYYY-MM-DD')} ~{' '}
                {moment(booking.endDate).format('YYYY-MM-DD')}
              </s.ItemBookingRange>
              {/* 결산 금액 */}
              <s.ItemAmount>
                {priceToFormat(booking.earning.earningAmount)}원
              </s.ItemAmount>
              {/* 결산 상태 */}
              <s.ItemStatus>
                {payStatusToString(booking.earning.payStatus)}
              </s.ItemStatus>
            </s.ItemContainer>
          </>
        ))}
      </s.EarningsDiv>

      {/* 총 금액 / 지급일 / 지급버튼*/}
      <s.PaymentsContainer>
        <>
          {/* 총 금액 */}
          <s.PaymentsAmountDiv>
            <s.PaymentsAmountText>
              총금액: {priceToFormat(getTotalAmount())}원
            </s.PaymentsAmountText>
          </s.PaymentsAmountDiv>

          {/* 지급날짜  */}
          <s.PaymentsDateDiv>
            <DatePicker
              selected={paymentsDate}
              onChange={onChangePaymentsDate}
              dateFormat={'yyyy-MM-dd'}
            ></DatePicker>
            (지급일 선택(평일))
          </s.PaymentsDateDiv>

          <Button text={'지급'} onClick={executePayments}></Button>
        </>
      </s.PaymentsContainer>
    </>
  );
};

export default PaymentsPresenter;
