/**
 * @author suyeon-jung
 * @date 2021.07.26
 * @description 삭제 버튼(for 이벤트 및 공지사항)
 */
import React, { useState } from 'react';
import CircleButton from 'components/atoms/CircleButton';
import { BsTrash } from 'react-icons/bs';
import Form from 'views/myzzym/Notification/Form/delete';

const NotificationDeleteButton = ({ item, pathname }) => {
  const [showModal, setShowModal] = useState(false);
  const changeShowModal = () => setShowModal(!showModal);
  return (
    <>
      <CircleButton onClick={changeShowModal}>
        <BsTrash size={20} style={{ margin: 0, padding: 0 }} />
      </CircleButton>
      {showModal && (
        <Form
          changeShowModal={changeShowModal}
          item={item}
          formTitle={pathname === '/myzzym/notice' ? '공지사항' : '이벤트'}
        />
      )}
    </>
  );
};

export default NotificationDeleteButton;
