/**
 * @author ydoni
 * @date 2021.07.20
 * @description inner 공통 container
 */

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 70%;
`;

const ContentContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default ContentContainer;
